import React from "react";

import axios from "axios";

import { useParams, useNavigate } from "react-router-dom";

import { AiFillCheckSquare } from "react-icons/ai";
import { GiCrossMark, GiCheckMark } from "react-icons/gi";

import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import A from "../../utils/API";
import VerifyProfile from "../../components/VerifyProfile";
import { BiRupee } from "react-icons/bi";
import FadeIn from "../../components/FadeIn";

export default function VideoWithQuizResult() {
  const navigate = useNavigate();
  const { closeWindow, eventLog, protect } = useUtils();
  const { user, headers } = useUser();
  const { id } = useParams();

  const [data, setData] = React.useState(false);

  const [showVerifyProfile, setShowVerifyProfile] = React.useState(false);

  const updateLastUPI = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.UPDATE_DEFAULT_UPI,
        { quizId: id },
        headers
      );
    } catch (err) {}
  };

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.ANSWER_RESULT_MULTI,
        { quizId: id },
        headers
      );
      setData(data);
      if (data?.upi) {
        updateLastUPI();
        setShowVerifyProfile(true);
      }
    } catch (err) {
      console.log(err);
      closeWindow();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col z-10">
      {/* {user?.verifiedStatus === false && data?.upi && showVerifyProfile ? (
        <VerifyProfile
          showClose={true}
          // onClose={() => {
          //   setShowVerifyProfile(false);
          // }}
        />
      ) : null} */}
      <div className="text-black h-full w-full flex justify-center items-center">
        {data ? (
          <FadeIn>
            <div className="py-5 flex flex-col items-center rounded-md w-[90vw] bg-[#09235D] bg-opacity-75 border-yellow-400 border-2 text-white p-3">
              <div className="flex flex-col items-center">
                {/* <img className="h-24 object-cover" src="/img/quiz.png" alt="" /> */}
                <p className="mt-4 text-4xl font-bold text-yellow-400">
                  PEPUL QUIZ
                </p>
                {data?.quizResult?.amount === 0 &&
                data?.quizType === "Free" &&
                data?.quizResult?.rightCount === 5 ? (
                  <>
                    <p className="mt-4 text-yellow-400 text-center max-w-[300px]">
                      You’re a quiz genius! 5 out of 5, well done!
                    </p>
                    <p className="mt-4 font-bold text-xl  text-center max-w-[300px]">
                      Better luck next time!
                    </p>
                    <p className="mt-4 text-yellow-400 text-center max-w-[300px]">
                      You’re not among the prize winning list!
                    </p>
                    <p className="mt-2 text-yellow-400 text-center">
                      Keep playing, maintain your streak and get the chance to
                      play the Special Streak Quiz!
                    </p>
                  </>
                ) : data?.quizResult?.amount === 0 &&
                  data?.quizType === "Streak" &&
                  data?.quizResult?.rightCount === 5 ? (
                  <>
                    <p className="mt-4 font-bold text-lg text-center max-w-[300px]">
                      Thanks for your participation!
                    </p>
                    <p className="mt-2 text-yellow-400 text-center">
                      We’re glad you enjoyed the quiz. Let’s keep the quiz
                      spirit alive!
                    </p>
                  </>
                ) : (
                  <>
                    {data?.quizResult?.amount !== 0 && (
                      <p className="mt-2 font-bold text-xl text-center">
                        Congratulations 🎉
                      </p>
                    )}
                    <img className="h-14 my-4" src="/img/coins1.gif" alt="" />
                    <p className="mt-2 text-xl text-center">You won</p>
                    <p className="flex items-center mt-1 text-4xl font-bold text-yellow-400">
                      <BiRupee />
                      {data?.quizResult?.amount}
                    </p>
                  </>
                )}
                <p className="text-lg mt-5">
                  Your {data?.completedQuiz?.length} Answers are
                </p>
                <div className="max-h-[40vh] overflow-y-scroll mt-2 flex flex-col gap-y-1">
                  {data &&
                    data?.completedQuiz &&
                    data?.completedQuiz?.map((each, idx) => (
                      <p
                        style={{
                          color:
                            each?.answerType === "RIGHT" ? "lightgreen" : "red",
                        }}
                        className="flex items-center text-lg"
                      >
                        {each?.answerType === "RIGHT" ? (
                          <>
                            {idx + 1}. Right
                            <GiCheckMark className="ml-1 text-2xl" />
                          </>
                        ) : (
                          <>
                            {idx + 1}. Wrong
                            <GiCrossMark className="ml-1 text-2xl" />
                          </>
                        )}
                      </p>
                    ))}
                </div>
                {/* <p className="mb-5 mt-2 text-lg text-center max-w-[80%]">
              The Result will be announced anytime before 11:55 PM
            </p> */}
              </div>

              <div
                className="opacity-25 w-full bg-black"
                style={{
                  height: 1,
                }}
              ></div>
              {/* <p className="mt-2 text-md text-center max-w-[80%]">
            Come back after winner's announcement to withdraw your amount
          </p> */}
              <button
                onClick={() => {
                  // navigate("/upi/" + id, {
                  //   replace: true,
                  // });
                  navigate("/home");
                }}
                className="mt-2 text-black font-bold max-w-[80vw] bg-yellow-400 md:w-[500px] sm:w-[90vw] py-3 rounded-lg text-md"
              >
                {data?.quizResult?.amount === 0
                  ? "Go Home"
                  : "Go Home to Claim"}
              </button>
              {/* {data?.upi ? (
            <>
              <p className="text-center mx-4">
                Your Payment will be credited to {protect(data.upi)}
              </p>
              <button
                onClick={() => {
                  navigate("/home?page=LEADERBOARD", {
                    replace: true,
                  });
                }}
                className="font-bold max-w-[80vw] bg-yellow-500 md:w-[500px] sm:w-[90vw] py-3 rounded-lg text-md mt-2"
              >
                Watch Live Leaderboard
              </button>
              <p
                onClick={() => {
                  navigate("/upi/" + id, {
                    replace: true,
                  });
                  eventLog("CLAIM_CLICK", id);
                  // playClick();
                }}
                className="mt-2 underline text-blue-800"
              >
                Change my UPI ID
              </p>
            </>
          ) : (
            <button
              onClick={() => {
                navigate("/upi/" + id, {
                  replace: true,
                });
                eventLog("CLAIM_CLICK", id);
                // playClick();
              }}
              className="max-w-[80vw] bg-yellow-500 md:w-[500px] sm:w-[90vw] py-3 rounded-lg text-lg text-bold font-bold my-5"
            >
              Get My Payment
            </button>
          )} */}
            </div>
          </FadeIn>
        ) : null}
      </div>
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
    </div>
  );
}

import create from "zustand";

const useStore = create((set) => ({
  user: null,
  setUser: (user) => set({ user: user }),
}));

export default function useUser() {
  const { user, setUser } = useStore();

  const headers = {
    headers: {
      Authorization: `Bearer ${user?.accessToken}`,
    },
  };

  return {
    user,
    setUser,
    headers,
  };
}

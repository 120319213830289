import axios from "axios";
import React from "react";
import { BiRupee } from "react-icons/bi";
import useUser from "../hooks/useUser";
import useUtils from "../hooks/useUtils";
import { format } from "date-fns";

import Lottie from "react-lottie";

import goldenTicketImage from "../assets/image/tickets.png";
import noProfile from "../assets/image/no_profile.svg";

import InfiniteScroll from "react-infinite-scroll-component";

import confettiJson from "../assets/lottie/confetti.json";

import fireGIF from "../assets/image/small_fire.gif";
import confGIF from "../assets/image/conf.gif";
// import fireJson from "../assets/lottie/fire.json";

import FadeIn from "../components/FadeIn";

import A from "../utils/API";
import { BsArrowUp } from "react-icons/bs";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function LeaderBoard() {
  const { headers, user } = useUser();
  const navigate = useNavigate();
  const [dates, setDate] = React.useState(false);
  const [winners, setWinners] = React.useState(false);
  const { addSuffix, renderDate, formatMoney } = useUtils();

  const [limit, setLimit] = React.useState(20);

  const [plans, setPlans] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(false);

  const fetchPlans = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_HOME_SUBSCRIPTIONS,
        {},
        headers
      );
      setPlans(data);
      if (data?.length > 0) {
        setSelectedPlan(data?.[0]?.planId);
      }
    } catch (err) {}
  };

  const fetchDates = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_LEADERBOARD_DATES,
        {
          planId: selectedPlan,
        },
        headers
      );
      setDate(data);
    } catch (err) {}
  };

  const fetchWinners = async () => {
    try {
      setWinners(false);
      const { data } = await axios.post(
        A.HOST + A.GET_LEADERBOARD_DATE_LIST,
        {
          quizId: selectedDate,
          limit: limit,
        },
        headers
      );
      setWinners(data);
    } catch (err) {}
  };

  React.useEffect(() => {
    fetchPlans();
  }, []);

  const loadMore = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_LEADERBOARD_DATE_LIST,
        {
          quizId: selectedDate,
          skip: winners?.winners?.length,
          limit: limit,
        },
        headers
      );

      let dataToAppend = data?.winners ?? [];
      let push = [];

      dataToAppend?.forEach((each) => {
        // console.log(winners?.winners);
        if (!winners?.winners?.find((e) => e?.userId === each?.userId))
          push?.push(each);
      });

      setWinners({
        ...winners,
        winners: [...winners?.winners, ...push],
      });
    } catch (err) {}
  };

  React.useEffect(() => {
    if (selectedPlan) fetchDates();
  }, [selectedPlan]);

  React.useEffect(() => {
    if (dates) setSelectedDate(dates?.[0]?.quizId);
  }, [dates]);

  React.useEffect(() => {
    if (selectedDate) fetchWinners();
    else setWinners(false);
  }, [selectedDate]);

  const renderTopSection = (winners) => {
    if (winners?.length > 3)
      return (
        <div
          className="mt-5 flex justify-center items-end relative my-2 mb-5 w-full"
          style={{
            zIndex: -1,
          }}
        >
          <div
            className="absolute"
            style={{
              bottom: -80,
            }}
          >
            <Lottie
              options={{
                animationData: confettiJson,
                loop: false,
                // path: "/lottie/fire.json",
              }}
              // height={20}
              width={"100vw"}
            />
          </div>
          <div className="relative z-10 flex flex-col items-center w-[25vw]">
            <div className="-z-10 absolute inset-0 bg-gradient-to-t from-[#717B8C] to-transparent opacity-50"></div>
            <div className="relative">
              <img
                src={winners?.[1]?.userImage}
                className="text-[6vw] my-2 border-4 border-[#717B8C] object-cover h-[18vw] w-[18vw] bg-black rounded-full"
                alt=""
              />
            </div>

            <h1 className="text-[2.5vw] max-w-[22vw] truncate">
              {winners?.[1]?.userName}
            </h1>
            <p className="text-[6vw]  text-[#FFB909] font-bold text-yellow-500 flex items-center">
              <img
                className="min-h-[1vw] w-[15vw]"
                src={"/img/rupee_small/" + winners?.[1]?.amount + ".png"}
                alt=""
              />
            </p>
            <p className="text-[2.5vw] mb-2 opacity-50">Prize Amount</p>
            <div className="rounded-tl-md flex bg-gradient-to-t from-[#717B8C] to-[#5e6776] justify-center items-center w-full h-10">
              <img src="/img/2nd.svg" alt="" />
            </div>
          </div>
          <div className="relative z-10 flex flex-col items-center w-[30vw]">
            <div className="-z-10 absolute inset-0 bg-gradient-to-t from-[#E9C85C] to-transparent opacity-50"></div>
            <img className="h-12" src="/img/crown.svg" alt="" />
            <img
              src={winners?.[0]?.userImage}
              className="text-[6vw] -mt-3 mb-2 border-4 border-[#E9C85C] object-cover h-[20vw] w-[20vw] bg-black rounded-full"
              alt=""
            />
            <h1 className="text-[3vw] max-w-[22vw] truncate">
              {winners?.[0]?.userName}
            </h1>
            <p className="text-[6vw] text-[#FFB909] font-bold text-yellow-500 flex items-center">
              <img
                className="min-h-[4vw] w-[25vw]"
                src={"/img/rupee_small/" + winners?.[0]?.amount + ".png"}
                alt=""
              />
            </p>
            <p className="text-[3vw] mb-2 opacity-50">Prize Amount</p>
            <div className="rounded-t-md flex justify-center bg-gradient-to-t from-[#E9C85C] to-[#bda24a] items-center w-full h-16">
              <img src="/img/1st.svg" alt="" />
            </div>
          </div>
          <div className="relative z-10 flex flex-col items-center w-[25vw]">
            <div className="-z-10 absolute inset-0 bg-gradient-to-t from-[#C88F48] to-transparent opacity-50"></div>
            <img
              src={winners?.[2]?.userImage}
              className="text-[6vw] my-2 border-4 border-[#C88F48] object-cover h-[18vw] w-[18vw] bg-black rounded-full"
              alt=""
            />
            <h1 className="text-[2.5vw] max-w-[18vw] truncate">
              {winners?.[2]?.userName}
            </h1>
            <p className="text-[6vw] text-[#FFB909] font-bold text-yellow-500 flex items-center">
              <img
                className="min-h-[4vw] w-[15vw]"
                src={"/img/rupee_small/" + winners?.[2]?.amount + ".png"}
                alt=""
              />
            </p>
            <p className="text-[2.5vw] mb-2 opacity-50">Prize Amount</p>
            <div className="rounded-tr-md flex justify-center bg-gradient-to-t from-[#C88F48] to-[#976c36] items-center w-full h-10">
              <img src="/img/3rd.svg" alt="" />
            </div>
          </div>

          {/* <div className="ml-20 mt-10 absolute flex justify-start items-center">
            <div className="flex flex-col justify-center items-center">
              <img
                src={winners?.[1]?.userImage}
                className="text-xl my-2 border-2 border-yellow-500 object-cover h-20 w-20 bg-black rounded-full"
                alt=""
              />
              <h1>{winners?.[1]?.userName}</h1>
              <p className="text-2xl font-bold text-yellow-500 flex items-center">
                <BiRupee />
                <p>10,000</p>
              </p>
              <p className="mb-10 opacity-50">Prize Amount</p>
            </div>
          </div>
          <div className="mr-20 mt-10 absolute inset-0 flex justify-end items-center">
            <div className="flex flex-col justify-center items-center">
              <img
                src={winners?.[2]?.userImage}
                className="text-xl my-2 border-2 border-yellow-500 object-cover h-20 w-20 bg-black rounded-full"
                alt=""
              />
              <h1>{winners?.[2]?.userName}</h1>
              <p className="text-2xl font-bold text-yellow-500 flex items-center">
                <BiRupee />
                <p>10,000</p>
              </p>
              <p className="mb-10 opacity-50">Prize Amount</p>
            </div>
          </div> */}
          {/* <img className="w-full h-full" src="/img/topper.png" alt="" /> */}
        </div>
      );
    else return null;
  };

  return (
    <div>
      <div className="sticky pt-1 top-0 z-10 bg-blue-500">
        {dates ? (
          <FadeIn>
            <div className="mt-5 pl-5 flex items-center overflow-x-scroll">
              {/* {JSON.stringify(plans, null, 4)} */}
              {plans &&
                plans?.map((each) =>
                  each?.planId === selectedPlan ? (
                    <p className="flex mx-2 bg-gray-500 px-4 py-2 rounded-tl-lg rounded-tr-lg">
                      {each?.amount === 0 ? (
                        "Free"
                      ) : (
                        <div className="flex items-center">
                          <BiRupee />
                          <p>{each?.amount}</p>
                        </div>
                      )}
                      <p className="ml-1">Quiz</p>
                    </p>
                  ) : (
                    <p
                      onClick={() => {
                        // if (each?.amount === 999)
                        //   navigate("/subscribePlan?plan=VIP");
                        // else

                        setSelectedPlan(each?.planId);
                      }}
                      className="mx-2 flex px-4 py-2 rounded-tl-lg rounded-tr-lg"
                    >
                      {each?.amount === 0 ? (
                        "Free"
                      ) : (
                        <div className="flex items-center">
                          <BiRupee />
                          <p>{each?.amount}</p>
                        </div>
                      )}
                      <p className="ml-1">Quiz</p>
                    </p>
                  )
                )}
              {/* <p className="ml-5 bg-gray-500 px-4 py-2 rounded-tl-lg rounded-tr-lg">
                Free Quiz
              </p> */}
              {/* <p className="ml-4">₹99 quiz</p>
              <p className="ml-4">₹999 quiz</p> */}
            </div>
            <div className="flex py-3 bg-gray-500">
              <div className="flex overflow-x-scroll">
                {dates &&
                  dates?.map((each, idx) =>
                    selectedDate === each?.quizId ? (
                      <p
                        onClick={() => {
                          setSelectedDate(each?.quizId);
                        }}
                        className="whitespace-nowrap py-2 bg-white rounded-full px-3 text-xs text-black"
                        style={{
                          marginLeft: idx === 0 ? 20 : 10,
                        }}
                      >
                        {each?.date ? renderDate(each?.date) : null}
                      </p>
                    ) : (
                      <p
                        onClick={() => {
                          setSelectedDate(each?.quizId);
                        }}
                        className="whitespace-nowrap text-white py-2 px-3 text-xs text-black"
                        style={{
                          marginLeft: idx === 0 ? 20 : 10,
                        }}
                      >
                        {each?.date ? renderDate(each?.date) : null}
                      </p>
                    )
                  )}
              </div>
            </div>
            {/* {winners && winners?.personalData && (
              <table
                onClick={() => {
                  //   if (each?.answerOrder) {
                  //     setSelectedDate(each);
                  //     setPage("WINNER");
                  //   }
                  //   eventLog("RESULT_SINGLE_CLICK");
                }}
                className={
                  winners?.personalData?.userName === user?.userName
                    ? "fixed w-full bottom-0 bg-yellow-500 text-black"
                    : ""
                }
              >
                <td>
                  <p className="ml-3 flex justify-center font-bold text-md py-3 p-2">
                    {addSuffix(winners?.personalData?.answerOrder)}
                  </p>
                </td>
                <td>
                  <img
                    className="border-2 min-h-12 min-w-12 h-12 w-12 bg-blue-800 object-cover rounded-full"
                    src={winners?.personalData?.userImage}
                    alt=""
                  />
                </td>
                <td>
                  <div className="py-3 p-2 flex items-center max-w-[200px] overflow-x-hidden">
                    <div className="flex flex-col">
                      <h1 className="text-md font-bold">
                        {winners?.personalData?.firstName &&
                        winners?.personalData?.lastName
                          ? winners?.personalData?.firstName +
                            " " +
                            winners?.personalData?.lastName
                          : winners?.personalData?.userName}
                      </h1>
                      <p className="text-xs">
                        @{winners?.personalData?.userName}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  {winners?.personalData?.userName === user?.userName ? (
                    <p className="flex justify-center border-2 bg-red-500 border-yellow-500 rounded-md text-yellow-500 py-3 flex items-center p-2 px-1 text-md md:text-xl font-bold">
                      <BiRupee />
                      <p>
                        {winners?.winners?.personalData?.amount
                          ? formatMoney(winners?.personalData?.amount)
                          : 0}
                      </p>
                    </p>
                  ) : (
                    <p className="flex justify-center border-2 bg-yellow-500 bg-opacity-25 border-yellow-500 rounded-md text-yellow-500 py-3 flex items-center p-2 px-1 text-md md:text-xl font-bold">
                      <BiRupee />
                      <p>
                        {winners?.personalData?.amount
                          ? formatMoney(winners?.personalData?.amount)
                          : 0}
                      </p>
                    </p>
                  )}
                </td>
              </table>
            )} */}
          </FadeIn>
        ) : null}
      </div>

      {/* <pre>{JSON.stringify(winners, null, 4)}</pre> */}
      {/* {!winners && <p className="min-h-[30px]"></p>} */}
      {winners &&
        winners?.personalData &&
        winners?.personalData?.userId &&
        winners?.personalData?.answerOrder &&
        !winners?.winners?.find((e) => e.userId === user?.userId) && (
          // <FadeIn>
          <table
            className="w-full fixed bottom-0 left-0 right-0 bg-yellow-500 bg-opacity-75"
            style={{
              zIndex: 20,
            }}
          >
            <tr
              onClick={() => {
                //   if (each?.answerOrder) {
                //     setSelectedDate(each);
                //     setPage("WINNER");
                //   }
                //   eventLog("RESULT_SINGLE_CLICK");
              }}
              className={"bg-yellow-500 text-black"}
            >
              <td>
                <p className="flex justify-center font-bold text-md py-3 p-2">
                  {addSuffix(winners?.personalData?.answerOrder)}
                </p>
              </td>
              <td>
                <img
                  className="hidden sm:block border-2 h-8 w-8 sm:min-h-12 sm:min-w-12 md:h-12 md:w-12 bg-blue-800 object-cover rounded-full"
                  src={winners?.personalData?.userImage}
                  alt=""
                />
              </td>
              <td>
                <div className="py-3 p-2 flex items-center max-w-[200px] overflow-x-hidden">
                  <div className="flex flex-col">
                    <h1 className="max-w-[40vw] text-md font-bold truncate">
                      {winners?.personalData?.userFirstLastName
                        ? winners?.personalData?.userFirstLastName
                        : winners?.personalData?.userName
                        ? winners?.personalData?.userName
                        : "Pepul User"}
                    </h1>
                    <p className="max-w-[40vw] text-xs truncate">
                      @{winners?.personalData?.userName}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                {winners?.personalData?.userName === user?.userName ? (
                  <p className="mr-5 flex justify-center border-2 bg-red-500 border-yellow-500 rounded-md text-yellow-500 py-3 flex items-center p-2 px-1 text-md md:text-xl font-bold">
                    <BiRupee />
                    <p>
                      {winners?.personalData?.amount
                        ? formatMoney(winners?.personalData?.amount)
                        : 0}
                    </p>
                  </p>
                ) : (
                  <p className="mr-5 flex justify-center border-2 bg-yellow-500 bg-opacity-25 border-yellow-500 rounded-md text-yellow-500 py-3 flex items-center p-2 px-1 text-md md:text-xl font-bold">
                    <BiRupee />
                    <p>
                      {winners?.personalData?.amount
                        ? formatMoney(winners?.personalData?.amount)
                        : 0}
                    </p>
                  </p>
                )}
              </td>
            </tr>
          </table>
          // </FadeIn>
        )}
      {winners && winners?.winners && winners?.winners?.length === 0 ? (
        <p className="text-center mt-5">No Winners Yet</p>
      ) : null}
      {winners && winners?.winners && winners?.winners?.length > 0 ? (
        <FadeIn>
          {winners?.liveData && (
            <div className="mt-3 flex justify-center items-center">
              <p className="bg-blue-500 text-center">
                Today's quiz is still active
              </p>
              <div className="relative py-2">
                <div className="absolute top-0 animate-ping ml-3 h-3 w-3 bg-green-500 rounded-full"></div>
                <div className="absolute top-0 ml-3 h-3 w-3 bg-green-500 rounded-full"></div>
              </div>
            </div>
          )}
          {/* <tr>
            <td>
              <div className="mt-2 flex justify-center">
                <p className="text-xs">Rank</p>
              </div>
            </td>
            <td>
              <div className="mt-2 flex">
                <p className="text-xs">Pepul Quiz Winners</p>
              </div>
            </td>
            <td>
              <div className="mt-2 flex">
                <p className="text-xs">Price Amount</p>
              </div>
            </td>
          </tr> */}
          <InfiniteScroll
            dataLength={winners?.winners?.length} //This is important field to render the next data
            next={loadMore}
            hasMore={true}
            loader={null}
            //   endMessage={
            //     <p style={{ textAlign: 'center' }}>
            //       <b>Yay! You have seen it all</b>
            //     </p>
            //   }
            // below props only if you need pull down functionality
            refreshFunction={() => {}}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={
            //   <h3 className="mt-10" style={{ textAlign: "center" }}>
            //     &#8595; Pull down to refresh
            //   </h3>
            // }
            // releaseToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>
            //     &#8593; Release to refresh
            //   </h3>
            // }
          >
            {winners?.liveData ? null : renderTopSection(winners?.winners)}
            <table className="max-w-[100vw] mt-1 w-full">
              {winners?.winners?.map((each, index) => (
                <tr
                  onClick={() => {
                    //   if (each?.answerOrder) {
                    //     setSelectedDate(each);
                    //     setPage("WINNER");
                    //   }
                    //   eventLog("RESULT_SINGLE_CLICK");
                  }}
                  className={
                    each?.userId === user?.userId
                      ? "bg-yellow-500 bg-opacity-75 text-black"
                      : index % 2 === 0
                      ? "bg-blue-500 bg-opacity-25"
                      : "bg-blue-400 bg-opacity-25"
                  }
                >
                  <td>
                    <p className="flex justify-center font-bold text-md py-3 p-2">
                      {addSuffix(index + 1)}
                    </p>
                  </td>
                  <td>
                    <div className="flex justify-center">
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = noProfile;
                          // currentTarget.style.display = "none";
                        }}
                        className="hidden sm:block border-2 h-8 w-8 sm:min-h-12 sm:min-w-12 md:h-12 md:w-12 bg-blue-100 object-cover rounded-full"
                        src={each?.userImage}
                        alt=""
                      />
                    </div>
                    {/* <div className="relative flex justify-center">
                      {each?.goldenTicket ? (
                        <div
                          style={{
                            top: "70%",
                          }}
                          className="absolute flex justify-center w-full"
                        >
                          <img
                            className="w-[50%]"
                            src={goldenTicketImage}
                            alt=""
                          />
                        </div>
                      ) : null}
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.style.display = "none";
                        }}
                        className="hidden sm:block border-2 h-8 w-8 sm:min-h-12 sm:min-w-12 md:h-12 md:w-12 bg-blue-800 object-cover rounded-full"
                        src={each?.userImage}
                        alt=""
                      />
                    </div> */}
                    {/* <div className="relative h-12 w-12"> */}
                    {/* </div> */}
                    {/* {each?.fireStatus ? "Fire" : "No Fire"} */}
                  </td>
                  <td>
                    <div className="py-3 p-2 flex items-center max-w-[200px] overflow-x-hidden">
                      <div className="flex flex-col">
                        <h1 className="max-w-[40vw] text-md font-bold truncate">
                          {each?.userFirstLastName
                            ? each?.userFirstLastName
                            : each?.userName
                            ? each?.userName
                            : "Pepul User"}
                        </h1>
                        <p className="flex items-center max-w-[40vw] text-xs truncate">
                          @{each?.userName ?? "pepul.user"}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    {winners?.liveData === true && each?.fireStatus ? (
                      <p className="flex items-center text-md mr-4">
                        {/* <Lottie
                          options={{
                            loop: true,
                            // path: "/lottie/fire.json",
                            animationData: fireJson,
                          }}
                          height={20}
                          width={20}
                        /> */}
                        {/* 🔥 */}
                        <img className="w-5" src={fireGIF} alt="" />
                        <p className="mx-0 ml-2">Streak</p>
                      </p>
                    ) : winners?.liveData === true ? (
                      <p className="flex items-center text-md mr-4">
                        {/* <Lottie
                      options={{
                        loop: true,
                        // path: "/lottie/fire.json",
                        animationData: fireJson,
                      }}
                      height={20}
                      width={20}
                    /> */}

                        <img className="w-5" src={confGIF} alt="" />
                        <p className="mx-0 ml-2">Welcome</p>
                      </p>
                    ) : null}
                    {winners?.liveData !== true && (
                      <>
                        {each?.userName === user?.userName ? (
                          <p className="mr-5 flex justify-center border-2 bg-red-500 border-yellow-500 rounded-md text-yellow-500 py-3 flex items-center p-2 px-1 text-md md:text-xl font-bold">
                            <BiRupee />
                            <p>
                              {each?.amount ? formatMoney(each?.amount) : 0}
                            </p>
                          </p>
                        ) : (
                          <p className="mr-5 flex justify-center border-2 bg-yellow-500 bg-opacity-25 border-yellow-500 rounded-md text-yellow-500 py-3 flex items-center p-2 px-1 text-md md:text-xl font-bold">
                            <BiRupee />
                            {each?.amount ? formatMoney(each?.amount) : 0}
                          </p>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </InfiniteScroll>
        </FadeIn>
      ) : null}
      {window?.scrollY > 100 && (
        <button
          onClick={() => {
            window?.scrollTo({
              left: 0,
              top: 0,
              behavior: "smooth",
            });
          }}
          className="fixed bottom-0 right-0 m-4"
        >
          <MdKeyboardArrowUp className="text-5xl bg-blue-800 rounded-full p-2" />
        </button>
      )}
    </div>
  );
}

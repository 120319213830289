import React from "react";

import "@fontsource/poppins";
import "@fontsource/poppins/800.css";
import "./styles/global.css";

import { MdOutlineWifiOff } from "react-icons/md";

import { Offline, Online } from "react-detect-offline";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";

import useUser from "./hooks/useUser";

import Home from "./pages";
import Participate from "./pages/participate";
import Results from "./pages/results";
import Upi from "./pages/upi";
import TimeOut from "./pages/timeout";
import WatchVideo from "./pages/watchVideo";
import Rules from "./pages/rules";
import VideoWithQuiz from "./pages/videoWithQuiz";
import VideoResult from "./pages/videoResult";
import Share from "./pages/share";
import Initiate from "./pages/initiate";
import PlanHome from "./pages/plansHome";
import SubscribePlan from "./pages/subscribePlan";
import CheckQuiz from "./pages/checkQuiz";
import QuizComplete from "./pages/quizComplete";
import QuizStartTime from "./pages/quizStartTime";
import Settings from "./pages/settings";

import MyPlanHistory from "./pages/myPlanHistory";
import Tickets from "./pages/tickets";
import Verify from "./pages/verify";
import PlayGame from "./pages/playGame";
import Errors from "./pages/errors";

function App() {
  const { user } = useUser();
  return (
    <>
      {/* <Offline>
        <div
          style={{
            zIndex: 999999,
          }}
          className="fixed py-1 bg-red-500 bottom-0 w-screen bg-black flex justify-center items-center"
        >
          <MdOutlineWifiOff />
          <p className="ml-2 text-md">No Internet Connection</p>
        </div>
      </Offline> */}
      <Router>
        <Routes>
          <Route path="/" element={<Initiate />}></Route>
          {/* <Route path="/planHome" element={<PlanHome />}></Route> */}
          <Route path="/home" element={<PlanHome />}></Route>
          <Route path="/checkQuizId/:id" element={<CheckQuiz />}></Route>
          <Route path="/quizComplete" element={<QuizComplete />}></Route>
          <Route
            path="/quizStartTime/:time"
            element={<QuizStartTime />}
          ></Route>
          <Route path="/subscribePlan" element={<SubscribePlan />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/playGame" element={<PlayGame />}></Route>
          <Route path="/tickets" element={<Tickets />}></Route>
          <Route path="/myPlanHistory" element={<MyPlanHistory />}></Route>
          <Route path="/share" element={<Share />}></Route>
          <Route path="/videoWithQuiz/:id" element={<VideoWithQuiz />}></Route>
          <Route path="/videoResult/:id" element={<VideoResult />}></Route>
          <Route path="/rules" element={<Rules />}></Route>
          <Route path="/verify" element={<Verify />}></Route>
          <Route path="/watchVideo/:id" element={<WatchVideo />}></Route>
          <Route path="/participate/:id" element={<Participate />}></Route>
          <Route path="/results/:id" element={<Results />}></Route>
          <Route path="/timeout" element={<TimeOut />}></Route>
          <Route path="/upi" element={<Upi />}></Route>
          <Route path="/error" element={<Errors />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;

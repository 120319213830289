import React, { useEffect } from "react";
import useUtils from "../../hooks/useUtils";

export default function Errors() {
  const { closeWindow, eventLog } = useUtils();
  useEffect(() => {
    eventLog("ERROR_PAGE");
    console.log("error page");
    closeWindow();
  }, []);
  return <div></div>;
}

import React from "react";
import {
  AiFillCloseCircle,
  AiOutlineWarning,
  AiTwotoneWarning,
} from "react-icons/ai";

import { GoVerified } from "react-icons/go";

import tick from "../assets/image/tick.svg";
import useUtils from "../hooks/useUtils";

export default function VideoNotViewed({
  showClose = false,
  onClose = () => {},
}) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      className="h-screen w-screen fixed flex flex-col justify-end z-50 transition duration-500"
      style={{
        opacity: show ? 1 : 0,
      }}
    >
      <div
        onClick={onClose}
        className="h-screen w-screen absolute bg-black bg-opacity-50"
      ></div>
      <div className="z-30 rounded-t-lg flex flex-col bg-[#252B37] p-5">
        {showClose ? (
          <div className="flex flex-col items-end">
            <AiFillCloseCircle onClick={onClose} className="text-2xl" />
          </div>
        ) : null}
        {/* <h1 className="font-bold text-center text-2xl mb-2">Oops</h1> */}
        <div className="flex justify-center items-center">
          {/* <img className="h-24" src={tick} alt="" /> */}
          <AiOutlineWarning className="text-red-400 text-5xl mb-3" />
        </div>
        <div className="flex justify-center">
          <p className="text-md max-w-[80vw] mb-3 text-center">
            It looks like you did not watched the entire video. Answering Quiz
            question without watching video is violation to the rules. So,
            Please watch the video And answer
          </p>
        </div>
        <button
          onClick={() => {
            onClose();
          }}
          className="font-bold py-3 rounded-md mx-3 bg-[#028BF7] px-3 text-center"
        >
          Watch Video
        </button>
      </div>
    </div>
  );
}

import create from "zustand";
import { persist } from "zustand/middleware";

import { english, tamil } from "../languages";

const useStore = create((set) => ({
  activeLang: "en",
  lang: english,
  setLang: (lang, json) => set({ lang: json, activeLang: lang }),
}));

export default function useLang() {
  const { lang, setLang, activeLang } = useStore();

  const switchLang = (langCode) => {
    if (langCode === "ta") setLang(langCode, tamil);
    if (langCode === "en") setLang(langCode, english);
  };

  return {
    lang,
    setLang,
    switchLang,
    activeLang,
  };
}

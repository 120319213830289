import React from "react";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import A from "../../utils/API";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function Settings() {
  const { user, headers } = useUser();
  const navigate = useNavigate();
  const {} = useUtils();

  return (
    <div className="h-screen pt-4 bg-blue-500">
      <div className="flex justify-center items-center">
        <IoIosArrowBack
          onClick={() => {
            navigate(-1);
          }}
          className="fixed m-2 mt-5 top-0 left-0 text-4xl"
        />
        <h1 className="mt-3 text-xl">Settings</h1>
      </div>
      <div
        onClick={() => {
          navigate("/myPlanHistory");
        }}
        className="flex justify-between items-center border-b-2 border-zinc-800"
      >
        <p className="m-5 text-md">My Plan History</p>
        <IoIosArrowForward className="mr-4 text-3xl" />
      </div>
    </div>
  );
}

import React from "react";

import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import ReactPlayer from "react-player";
import axios from "axios";
import { Circle } from "rc-progress";
import Lottie from "react-lottie";

import { format } from "date-fns";

import { BiCheck, BiRupee } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";

import { GiSwapBag } from "react-icons/gi";

import A from "../../utils/API";

import useLang from "../../hooks/useLang";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import games from "../../json/games.json";
import somethingJson from "../../assets/lottie/something.json";
import loadingJson from "../../assets/lottie/loading.json";

import { IoIosArrowBack } from "react-icons/io";
import {
  AiFillGift,
  AiOutlineCloseCircle,
  AiOutlineDoubleRight,
  AiOutlineExclamationCircle,
  AiOutlineGift,
  AiOutlineTrophy,
} from "react-icons/ai";
import Results from "../../components/Results";
import LeaderBoard from "../../components/Leaderboard";
import FadeIn from "../../components/FadeIn";
import { FiSettings } from "react-icons/fi";
import GoldenTicket from "../../components/GoldenTicket";
import VerifyProfile from "../../components/VerifyProfile";
import Payout from "../../components/Payout";
import InviteEarn from "../../components/InviteEarn";
import PremiumPrimeStop from "../../components/PremiumPrimeStop";
import RenderFAQ from "../../components/RenderFAQ";
import { BsFillInfoCircleFill, BsInfoCircleFill } from "react-icons/bs";

const Timer = ({
  startTime = new Date(),
  endTime = new Date(),
  serverTime = new Date(),
  onComplete = () => {},
  onParticipateClick = () => {},
}) => {
  const navigate = useNavigate();
  const { lang, switchLang, activeLang } = useLang();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <>
          <button
            onClick={onParticipateClick}
            className="mt-5 font-bold text-black text-xl bg-white w-[300px] py-3 rounded-md"
          >
            {lang?.PARTICIPATE}
          </button>
          {/* <p>{endTime}</p> */}
        </>
      );
    } else {
      // Render a countdown
      return (
        <div className="flex flex-col items-center">
          <p className="mt-5 text-xs font-medium">{lang?.TIME_LEFT}</p>
          <div className="flex my-2">
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                <p>{zeroPad(hours)}</p>
                <p className="text-xs text-white opacity-50">{lang?.HOURS}</p>
              </div>
            </p>
            <p>:</p>
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                <p>{zeroPad(minutes)}</p>
                <p className="text-xs text-white opacity-50">{lang?.MINUTES}</p>
              </div>
            </p>
            <p>:</p>
            <p className="text-yellow-500">
              <div className="flex items-center flex-col">
                <p>{zeroPad(seconds)}</p>
                <p className="text-xs text-white opacity-50">{lang?.SECONDS}</p>
              </div>
            </p>
          </div>
          {/* <p className="mt-2 text-xs font-medium">
            {lang?.DAILY_QUIZ} <b>8:00 pm to 8:05 pm</b>
          </p> */}
        </div>
      );
    }
  };
  return (
    <div className="text-2xl font-bold flex gap-x-4">
      <Countdown
        precision={new Date().getTime() - new Date(serverTime).getTime()}
        onComplete={onComplete}
        date={startTime}
        renderer={renderer}
      />
    </div>
  );
};

const PlanHome = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    closeWindow,
    eventLog,
    addSuffix,
    playClick,
    openComplete,
    openVerify,
    openUser,
    formatMoney,
    openCreator,
    openUrl,
    getOS,
  } = useUtils();

  const [parti, setParti] = React.useState(false);

  const [unclaimQuiz, setUnClaimQuiz] = React.useState(false);

  const [ticket, showTicket] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      showTicket(true);
    }, 1000);
  }, []);

  const [selectedSection, setSelectedSection] = React.useState("QUIZ");

  const { lang, switchLang, activeLang } = useLang();
  const { user, setUser, headers } = useUser();

  const [showClaimMoney, setClaimMoney] = React.useState(false);

  const [tickets, setTickets] = React.useState(false);

  const [showInvite, setShowInvite] = React.useState(false);
  const [inviteLoaded, setInviteLoaded] = React.useState(null);
  const [showInfo, setShowInfo] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [howWorks, setHowWorks] = React.useState(false);
  const [streaksData, setStreaksData] = React.useState(null);
  const [popup, setPopup] = React.useState(false);

  if (!user) navigate("/");

  const divRef = React.useRef(null);

  const [data, setData] = React.useState(false);

  const validateUser = () => {
    axios
      .post(A.HOST + A.VALIDATE_USER, {}, headers)
      .then(({ data }) => {
        if (data) setUser(data);
      })
      .catch((err) => {
        eventLog("VALIDATE_USER_FAILED");
        setUser(null);
        navigate("/error");
      });
  };

  React.useEffect(() => {
    if (howWorks) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [howWorks]);

  // React.useEffect(() => {
  //   if (user) {
  //     validateUser(user?.accessToken);
  //     eventLog("VISITED");
  //   }
  // }, [user?.accessToken]);

  // React.useEffect(() => {
  //   if (user && searchParams?.get("page") === "QUIZ") fetchHomeData();
  // }, [user?.accessToken, searchParams]);

  React.useEffect(() => {
    if (user?.userId && A.PHP_UPDATE)
      axios
        .post(A.PHP_UPDATE, { userId: user?.userId })
        .then((data) => console.log(JSON.stringify(data, null, 4)));
  }, [user]);

  const fetchHomeData = () => {
    axios
      .post(A.HOST + A.GET_HOME_SUBSCRIPTIONS, {}, headers)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        eventLog("GET_HOME_SUBSCRIPTIONS_FAILED");
        navigate("/error");
      });
  };
  const fetchInviteStatus = () => {
    axios
      .post(A.HOST + A.GET_INVITE_STATUS, {}, headers)
      .then(({ data }) => {
        setInviteLoaded(data);
      })
      .catch((err) => {
        eventLog("GET_INVITE_STATUS_FAILED");
        navigate("/error");
      });
  };

  const fetchTickets = () => {
    axios
      .post(A.HOST + A.GET_GOLDEN_TICKET_COUNT, {}, headers)
      .then(({ data }) => {
        setTickets(data);
      })
      .catch((err) => {
        eventLog("GET_GOLDEN_TICKET_COUNT_FAILED");
        navigate("/error");
      });
  };

  const getStreaks = () => {
    axios
      .post(A.HOST + A.GET_STREAKS, {}, headers)
      .then(({ data }) => {
        setStreaksData(data);
        if (data?.streakActive === "ACTIVE") setShow(true);
      })
      .catch((err) => {
        eventLog("GET_STREAKS_FAILED");
        navigate("/error");
      });
  };
  // const fetchResult = () =>
  //   axios.post(A.HOST + A.GET_UNCLAIMED_QUIZ, {}, headers).then(({ data }) => {
  //     if (data?.claimData) setUnClaimQuiz(data?.claimData);
  //   });
  const fetchResult = () => {
    axios
      .post(A.HOST + A.GET_TOTAL_UNCLAIMED_QUIZ, {}, headers)
      .then(({ data }) => {
        eventLog("GET_TOTAL_UNCLAIMED_QUIZ");
        setUnClaimQuiz(data?.totalUnclaimedAmount);
      })
      .catch((err) => {
        eventLog("GET_TOTAL_UNCLAIMED_QUIZ_FAILED");
        navigate("/error");
      });
  };

  React.useEffect(() => {
    // fetchTickets();
    // fetchResult();
    // fetchInviteStatus();
    // getStreaks();
    // setPopup(true);
  }, []);

  const onBlur = () => {
    // closeWindow();
  };

  React.useEffect(() => {
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  // React.useEffect(() => {
  //   const closeWindow = () =>
  //     setTimeout(() => {
  //       window.open("about:blank", "_self");
  //       window.close();
  //     }, 3000);
  //   closeWindow();
  // }, []);

  React.useEffect(() => {
    if (!searchParams.get("page")) setSearchParams({ page: "QUIZ" });
  }, []);

  const renderOldParticipate = () => (
    <div className="-mt-2 pb-10 gap my-5 mt-0 flex flex-col justify-center items-center">
      <img className="h-40" src="/img/quiz.png" alt="" />
      <p className="mt-1 font-bold text-center text-2xl">
        {lang?.WATCH_WIN_DAILY}
      </p>
      <p className="mt-1 font-bold text-center text-md">Upto</p>
      <img className="mt-3 h-10" src="/img/10000.png" alt="" />
      <p className="text-lg mt-2">{lang?.HOW_TO_PARTICIPATE}</p>
      {A.MODE === "STAGE" && (
        <div className="flex flex-col mt-4">
          <div className="flex">
            <p
              onClick={() => {
                openVerify();
              }}
              className="bg-blue-600 px-5 py-1 rounded-full"
            >
              Verify
            </p>
            <p
              onClick={() => {
                openComplete();
              }}
              className="ml-2 bg-blue-600 px-5 py-1 rounded-full"
            >
              Complete Profile
            </p>
          </div>
          <div className="flex mt-3">
            <p
              onClick={() => {
                openUser();
              }}
              className="ml-2 bg-blue-600 px-5 py-1 rounded-full"
            >
              Open Profile
            </p>
            <p
              onClick={() => {
                openCreator();
              }}
              className="ml-2 bg-blue-600 px-5 py-1 rounded-full"
            >
              Open Creator
            </p>
          </div>
        </div>
      )}
      <div className="px-5">
        <video
          className="mt-5 w-full w-[90vw] max-w-[600px] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
          // autoPlay
          // muted
          poster={inviteLoaded?.quizIntroVideoThumbnail}
          controlsList="nodownload"
          loop
          controls
        >
          <source src={inviteLoaded?.quizIntroVideo} type="video/mp4" />
        </video>
      </div>
      <div
        onClick={() => {
          navigate("/rules");
        }}
        className="max-w-[80vw] mt-4 flex justify-center w-full items-center"
      >
        <FaInfoCircle className="text-xl" />
        <p className="text-center ml-2">
          <span className="ml-1 font-bold text-yellow-500">
            {lang?.RULES_GUIDELINES}
          </span>
        </p>
      </div>
      {/* {data?.remainingSeconds} */}
      {/* {data?.openTime && (
    <Timer
      serverTime={data?.serverTime}
      onParticipateClick={() => {
        eventLog("PARTICIPATE", data?.quizId);
        navigate("/videoWithQuiz/" + data?.quizId);
        playClick();
      }}
      onComplete={() => {
        setTimeout(() => {
          fetchHomeData();
        }, 200);
      }}
      startTime={data?.openTime}
    />
  )} */}
      <>
        <button
          onClick={() => {
            if (data?.openStatus) {
              eventLog("PARTICIPATE", data?.quizId);
              navigate("/videoWithQuiz/" + data?.quizId);
              playClick();
            }
          }}
          style={{
            opacity: data?.openStatus ? 1 : 0.3,
          }}
          className="mt-5 font-bold text-black text-xl bg-yellow-500 w-[300px] py-3 rounded-md"
        >
          {lang?.PARTICIPATE}
        </button>
        {showClaimMoney && (
          <button
            onClick={() => {
              navigate("/upi/" + showClaimMoney);
              playClick();
            }}
            className="mt-5 font-bold text-black text-sm bg-white w-[300px] py-3 rounded-md"
          >
            Claim Today's Winning Money
          </button>
        )}
      </>
      {data?.openStatus === false &&
        data?.openTime &&
        data?.uncompleteCount !== 0 && (
          <p className="text-sm mt-3">
            Quiz starts at{" "}
            {data?.openTime && format(new Date(data?.openTime), "p")}
          </p>
        )}
      {data?.uncompleteCount === 0 && (
        <p className="text-sm mt-3">
          Results will be published before 11:55 PM
        </p>
      )}
      {data &&
        data?.uncompleteCount !== 0 &&
        data?.completedCount &&
        data?.completedCount !== 0 && (
          <p className="text-sm mt-3">
            {data?.uncompleteCount} Question Pending !
          </p>
        )}
      {/* {data?.openStatus === true && data?.closeTime && (
    <p className="opacity-50 text-sm mt-3">
      Quiz ends at {format(new Date(data?.closeTime), "p")}
    </p>
  )} */}
      {/* <p>Today Quiz Ends at 9 PM</p> */}
      {data?.videoId && data?.openStatus === false && (
        <div className="mt-5 flex justify-center items-center gap-x-4 w-full bg-red-500 p-3 px-4">
          <h1 className="text-xl font-bold max-w-[250px]">
            {lang?.TODAY_QUIZ}
          </h1>
          <div
            onClick={() => {
              window?.JSBridge?.showMessageInNative(
                JSON.stringify({
                  type: "VIDEO",
                  videoId: data?.videoId,
                  text: "",
                  url: "",
                })
              );
              window?.webkit?.messageHandlers?.messageObserver?.postMessage(
                JSON.stringify({
                  type: "VIDEO",
                  videoId: data?.videoId,
                  text: "",
                  url: "",
                })
              );
              eventLog("WATCH_NOW_CLICKED", data?.quizId);
            }}
            // href="https://pepulstagestage.page.link/PqzkxEngqeCA2zNB6"
            className="bg-yellow-500 text-center text-black px-4 rounded-md py-2 font-bold"
          >
            {lang?.WATCH_NOW}
          </div>
        </div>
      )}
    </div>
  );

  const renderButton = (amount = 0, each) => {
    if (amount === 0)
      return (
        <button
          // onClick={() => {
          //   if (each?.subscribedStatus) {
          //     navigate("/checkQuizId/" + each?.planId, {
          //       replace: true,
          //     });
          //     eventLog("FREE_CLICK");
          //     playClick();
          //   }
          // }}
          className="text-xs md:text-md font-bold bg-yellow-400 text-black px-5 rounded-md py-2"
        >
          Participate
        </button>
      );
    if (amount === 99)
      return each?.subscribedStatus === true ? (
        <button
          // onClick={() => {
          //   eventLog("PREMIUM_CLICK");
          //   if (each?.subscribedStatus) {
          //     navigate("/checkQuizId/" + each?.planId, {
          //       replace: true,
          //     });
          //     playClick();
          //   } else navigate("/subscribePlan?plan=PREMIUM");
          // }}
          className="text-xs md:text-md font-bold bg-yellow-400 text-black px-5 rounded-md py-2"
        >
          Participate
        </button>
      ) : (
        <button
          // onClick={() => {
          //   eventLog("PREMIUM_CLICK");
          //   if (each?.subscribedStatus) {
          //     navigate("/checkQuizId/" + each?.planId, {
          //       replace: true,
          //     });
          //     playClick();
          //   } else navigate("/subscribePlan?plan=PREMIUM");
          // }}
          className="text-xs md:text-md font-bold bg-yellow-400 text-black px-5 rounded-md py-1"
        >
          FREE FOR
          <br />
          PREMIUM USERS
        </button>
      );
    if (amount === 999 || amount === 499)
      return each?.subscribedStatus === true ? (
        <button
          // onClick={() => {
          //   eventLog("VIP_CLICK");
          //   if (each?.subscribedStatus) {
          //     navigate("/checkQuizId/" + each?.planId, {
          //       replace: true,
          //     });
          //     playClick();
          //   } else navigate("/subscribePlan?plan=VIP");
          // }}
          className="flex items-center text-xs md:text-md font-bold bg-yellow-400 text-black text-black px-5 rounded-md py-2"
        >
          <p>Participate</p>
        </button>
      ) : (
        <button
          // onClick={() => {
          //   eventLog("VIP_CLICK");
          //   if (each?.subscribedStatus) {
          //     navigate("/checkQuizId/" + each?.planId, {
          //       replace: true,
          //     });
          //     playClick();
          //   } else navigate("/subscribePlan?plan=VIP");
          // }}
          className="text-xs md:text-md font-bold bg-yellow-400 text-black px-5 rounded-md py-1"
        >
          FREE FOR
          <br />
          PRIME USERS
        </button>
      );
  };

  return inviteLoaded ? (
    <>
      <FadeIn>
        <div
          ref={divRef}
          className="h-screen w-screen flex flex-col z-10 bg-blue-900"
        >
          {/* {tickets && tickets?.ticket && !unclaimQuiz ? (
          <GoldenTicket
            ticket={tickets?.ticket}
            onClose={() => {
              setTickets({ ...tickets, ticket: null });
            }}
          />
        ) : null} */}
          {showInfo && (
            <PremiumPrimeStop
              showClose={true}
              openVerify={() => {
                setShowInfo(false);
              }}
              onClose={() => {
                setShowInfo(false);
              }}
            />
          )}
          {/* {unclaimQuiz && (
            <Payout
              onClose={() => {
                setUnClaimQuiz(false);
              }}
              onCloseWithAnotherFetch={() => {
                setUnClaimQuiz(false);
                setTimeout(() => {
                  fetchResult();
                }, 1000);
              }}
              quiz={unclaimQuiz}
            />
          )} */}
          {showInvite ? (
            <InviteEarn
              inviteData={inviteLoaded}
              onClose={() => {
                setShowInvite(false);
                fetchInviteStatus();
              }}
            />
          ) : null}
          <div className="my-2 flex items-center w-full py-2">
            <IoIosArrowBack
              onClick={() => {
                closeWindow();
              }}
              className="z-10 mx-3 text-2xl md:text-4xl"
            />
            {/* {inviteLoaded?.status && (
              <div
                onClick={() => {
                  setShowInvite(true);
                  eventLog("REFER_BONUS_CLICK");
                }}
                className="flex-1 justify-center flex items-center"
              >
                <img src="/img/bonus.gif" className="h-8 text-xl mr-1" />
                <p className="font-bold flex items-center">
                  You Won
                  <span className="text-xl flex text-yellow-500 items-center">
                    <BiRupee className="ml-1 -mr-1" />
                    <p>{inviteLoaded?.referAmount}</p>
                  </span>
                  <div className="flex items-center relative">
                    <button className="text-xs px-2 ml-3 py-1 rounded-full">
                      Claim Now
                    </button>
                    <AiOutlineDoubleRight />
                    
                  </div>
                </p>
              </div>
            )} */}
            {/* <IoIosArrowBack className="opacity-0 z-10 m-2 mb-1 text-2xl md:text-4xl" /> */}
            {/* <div className="absolute w-full flex justify-center items-center">
            <img className="h-16" src="/img/quiz.png" alt="" />
          </div> */}
            {/* <div className="flex items-center">
            {tickets && (
              <div
                onClick={() => {
                  navigate("/tickets");
                  eventLog("TICKET_ICON_CLICK");
                }}
                className="z-10 border-2 border-[#496F9C] bg-gradient-to-r from-[#496F9C] to-transparent py-1 border-1 px-3 rounded-full flex items-center"
              >
                <img className="h-4" src="/img/tickets.png" alt="" />
                <p className="ml-2 font-bold">{tickets?.ticketsCount}</p>
              </div>
            )}
            <FiSettings
              onClick={() => {
                eventLog("SETTINGS_CLICK");
                navigate("/settings");
              }}
              className="z-10 m-2 mr-5 mb-1 text-xl md:text-2xl"
            />
          </div> */}
          </div>
          {/* inviteLoaded?.playedCount === 0  */}
          <>
            {false && (
              <div
                className="z-10 text-white rounded-md flex mx-2 sm:mx-5 md:mx-5"
                style={
                  {
                    //  backgroundColor: "#366092",
                  }
                }
              >
                <p
                  onClick={() =>
                    setSearchParams({
                      page: "QUIZ",
                    })
                  }
                  style={{
                    borderBottomWidth:
                      searchParams.get("page") === "QUIZ" ? 2 : 0,
                  }}
                  className="font-bold w-1/2 p-2 flex justify-center items-center"
                >
                  Quiz
                </p>
                {inviteLoaded?.leaderBoardStatus && (
                  <p
                    // onClick={() => setSelectedSection("RESULTS")}
                    onClick={() => {
                      // navigate("/home?page=RESULTS");
                      eventLog("LEADERBOARD_CLICK");
                      setSearchParams({
                        page: "LEADERBOARD",
                      });
                    }}
                    style={{
                      borderBottomWidth:
                        searchParams.get("page") === "LEADERBOARD" ? 2 : 0,
                    }}
                    className="font-bold w-1/2 p-2 flex justify-center items-center"
                  >
                    LeaderBoard
                  </p>
                )}
                <p
                  // onClick={() => setSelectedSection("RESULTS")}
                  onClick={() => {
                    // navigate("/home?page=RESULTS");
                    eventLog("RESULTS_CLICK");
                    setSearchParams({
                      page: "RESULTS",
                    });
                  }}
                  style={{
                    borderBottomWidth:
                      searchParams.get("page") === "RESULTS" ? 2 : 0,
                  }}
                  className="font-bold w-1/2 p-2 flex justify-center items-center"
                >
                  My Earnings
                </p>
                {/* <p
            onClick={() => setSelectedSection("LEADERBOARD")}
            style={{
              borderBottomWidth: selectedSection === "LEADERBOARD" ? 2 : 0,
            }}
            className="w-1/3 p-2 flex justify-center items-center"
          >
            Leaderboard
          </p> */}
              </div>
            )}
            {searchParams.get("page") === "QUIZ" ? (
              // <FadeIn>{renderOldParticipate()}</FadeIn>
              data ? (
                <FadeIn>
                  {true ? (
                    <div className="mt-1 flex-1 flex flex-col items-center">
                      {/* <h1 className="text-lg font-bold text-center my-1">
                      Intro
                    </h1> */}
                      {false ? (
                        <div className="w-full px-4">
                          <div
                            style={{
                              backgroundImage: "url(/paradise-city.png)",
                            }}
                            className="bg-[#544D01] overflow-hidden rounded-md border-[#FFEF4E] border-2 py-4 relative flex flex-col items-center"
                          >
                            {/* <img
                          src
                          className="absolute object-cover inset-0 bg-blue-400"
                          style={{
                            zIndex: -10,
                          }}
                        /> */}
                            <p className="text-md">
                              Indulge in the lavish lifestyle of a
                            </p>
                            {/* <p className="mt-1 text-xs">Upto</p> */}
                            <div className="mt-3 flex flex-col items-center">
                              <img
                                className="-mt-2 h-[60px]"
                                src="/luxury.png"
                                alt=""
                              />
                              <img
                                className="-mt-5 h-[150px]"
                                src="/adityan.png"
                                alt=""
                              />
                            </div>
                            <div className="mt-2 w-full flex flex-col">
                              <button
                                onClick={() => {
                                  eventLog("PALACE_CLICK");
                                  openUrl("https://www.cobuild.in/adityaram/");
                                }}
                                className="mx-5 mt-5 py-2 rounded-md font-bold bg-white text-black"
                              >
                                Visit Now
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="w-full px-4">
                        <div
                          className="flex flex-col mx-auto bg-gradient-to-br from-[#6c6e6f] to-[#131824] w-full min-h-48 h-auto border border-gray-400 rounded-md py-4 max-w-[400px]"
                          onClick={() => !show && setHowWorks(true)}
                        >
                          <p
                            className="text-sm text-yellow-400 ml-auto w-fit flex justify-end items-center mr-2 cursor-pointer"
                            onClick={() => setHowWorks(true)}
                          >
                            How it works
                            <AiOutlineExclamationCircle className="ml-1 text-xl" />
                          </p>
                          <div className="flex justify-between items-center w-full max-w-[360px] mx-auto px-4">
                            <div className="flex flex-col justify-between items-center">
                              <p className="font-bold text-base">WIN UPTO</p>
                              <p className="flex items-center font-bold text-4xl my-2">
                                <BiRupee className="" />
                                1000
                              </p>
                              <button
                                className={`bg-yellow-400 text-black text-sm font-bold px-4 py-2 rounded-lg ${
                                  show && streaksData?.moreDays === 0
                                    ? "opacity-100"
                                    : "opacity-50"
                                }`}
                                onClick={() => {
                                  if (
                                    show &&
                                    streaksData?.moreDays === 0 &&
                                    data?.[3]?.planId
                                  )
                                    navigate(
                                      "/checkQuizId/" + data?.[3]?.planId
                                    );
                                }}
                                // disabled={
                                //   show && streaksData?.moreDays === 0
                                //     ? false
                                //     : true
                                // }
                              >
                                {streaksData?.moreDays > 0
                                  ? `${streaksData?.moreDays} Days more`
                                  : show && streaksData?.moreDays === 0
                                  ? "Play now"
                                  : "Streak Quiz"}
                              </button>
                            </div>
                            <div className="relative mr-2 mt-2">
                              {!show ? (
                                <img
                                  src="./img/streakLocked.png"
                                  alt=""
                                  className="w-[60px]"
                                />
                              ) : (
                                <img
                                  src="./img/streak7.png"
                                  alt=""
                                  className="w-[60px]"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between items-center mt-4 mx-4 md:mx-6">
                            {[...new Array(6)]?.map((each, i) => (
                              <img
                                src={
                                  i >= streaksData?.streakCount
                                    ? `./img/streakLockedSmall.png`
                                    : `./img/streak${i + 1}.png`
                                }
                                alt=""
                                className="w-[30px]"
                                key={i}
                              />
                            ))}
                          </div>
                          {!show ? (
                            <p className="flex justify-center items-center text-yellow-400 text-center text-xs mt-3 mx-4">
                              Maintain streaks for 7 days. So you can play the
                              Streak Quiz on{" "}
                              {streaksData?.nextQuiz &&
                                format(
                                  new Date(streaksData?.nextQuiz),
                                  "do MMMM"
                                )}
                              .
                            </p>
                          ) : (
                            <p className="flex justify-center items-center text-yellow-400 text-center text-xs mt-3 mx-4">
                              Congratulations 🎉 You've achieved a 7-day streak.
                              Streak Quiz Enable on{" "}
                              {show && streaksData?.moreDays === 0
                                ? "now"
                                : format(
                                    new Date(streaksData?.nextQuiz),
                                    "do MMMM"
                                  )}
                              .
                            </p>
                          )}
                        </div>
                      </div> */}
                      {/* {unclaimQuiz > 0 && (
                        <div className="w-full px-4">
                          <div className="flex flex-col mx-auto bg-gradient-to-br from-[#6c6e6f] to-[#131824] w-full min-h-48 h-auto border border-gray-400 rounded-md py-8 max-w-[400px]">
                            <div className="flex justify-between items-center w-full max-w-[360px] mx-auto px-4">
                              <div className="flex flex-col justify-between items-start">
                                <p className="font-bold text-base">
                                  Reward Amount
                                </p>
                                <p className="flex items-center font-bold text-3xl my-2">
                                  <BiRupee className="" />
                                  {unclaimQuiz}
                                </p>
                                <button
                                  className={`bg-yellow-400 text-black text-sm font-bold px-4 py-2 rounded-lg`}
                                  onClick={() => {
                                    if (unclaimQuiz > 0) {
                                      navigate("/upi");
                                      eventLog("WITHDRAW_NOW_CLICK");
                                    }
                                  }}
                                  // disabled={unclaimQuiz >= 10 ? false : true}
                                >
                                  Claim Now
                                </button>
                              </div>
                              <div className="relative">
                                <div className="shadow-[1px_1px_9px_0px_rgba(250,204,21,0.5)] bg-[#FFF34A] w-[120px] rounded-full">
                                  <Circle
                                    className=""
                                    trailWidth={4}
                                    strokeWidth={4}
                                    percent={100}
                                    strokeColor="#ffd320"
                                    trailColor="#d3d3d4"
                                    style={{
                                      boxShadow:
                                        "1px 1px 5px 6px rgba(141,115,11,1) inset",
                                      borderRadius: "100%",
                                    }}
                                  />
                                </div>
                                <div className="text-center absolute inset-0 flex flex-col items-center justify-center text-[#A95F0B]">
                                  <p className="font-bold text-[10px] line">
                                    CLAIM YOUR
                                  </p>
                                  <p className="text-lg font-bold">REWARD</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      <div
                        style={{
                          backgroundImage: "url(/img/bg.png)",
                        }}
                        className={`rounded-2xl border-[#24639f] ${
                          unclaimQuiz > 0
                            ? "w-[260px] min-h-[360px]"
                            : "w-[80vw] max-w-[360px] h-[60vh] min-h-[380px]"
                        } border-2 mt-16 mb-4 px-4 py-4 relative flex flex-col justify-center items-center`}
                      >
                        {/* <img
                          src
                          className="absolute object-cover inset-0 bg-blue-400"
                          style={{
                            zIndex: -10,
                          }}
                        /> */}
                        {unclaimQuiz > 0 ? (
                          <img
                            src="./img/questionMark.png"
                            alt=""
                            className="absolute -top-16 w-32"
                          />
                        ) : (
                          <img
                            src="./img/questionMark.png"
                            alt=""
                            className="w-24"
                          />
                        )}

                        {/* <p className="text-center text-yellow-400 uppercase font-semibold text-base">
                          Thanks for participating in our apps quiz
                        </p> */}
                        {/* <p className="mt-1 text-xs">Upto</p> */}

                        {unclaimQuiz > 0 ? (
                          <div>
                            <p className="text-3xl text-center text-yellow-400 font-bold mt-14">
                              Pepul Quiz Ends
                            </p>
                            <p className="text-center text-[12px] text-white font-bold">
                              <span className="text-3xl">🎉</span> <br />
                              Thanks for participated in our apps quiz. Please
                              verify and withdraw the amount from your wallet
                              {/* before Saturday{" "}
                              <span className="text-yellow-400 font-bold text-[13px] italic">
                                ( January 20th )
                              </span> */}
                              .
                            </p>
                            <div className="w-full flex flex-col">
                              <button
                                onClick={() => {
                                  closeWindow();
                                }}
                                className="mx-5 mt-5 py-2 rounded-md font-bold bg-yellow-400 text-black"
                              >
                                Close
                              </button>
                              {/* <button
                            className={`mx-5 mt-5 py-2 rounded-md font-bold bg-yellow-400 text-black ${
                              unclaimQuiz >= 10 ? "opacity-100" : "opacity-50"
                            }`}
                            onClick={() => {
                              if (unclaimQuiz >= 10) {
                                navigate("/upi");
                                eventLog("WITHDRAW_NOW_CLICK");
                              }
                            }}
                            disabled={unclaimQuiz >= 10 ? false : true}
                          >
                            Claim Now
                          </button> */}
                            </div>
                          </div>
                        ) : (
                          <div className="">
                            <p className="text-2xl text-center text-yellow-400 font-bold mt-4">
                              Pepul Quiz Ends
                            </p>
                            <p className="text-center text-[12px] mt-2 text-white font-bold">
                              <span className="text-3xl">🎉</span> <br />
                              Thank you for actively participated in our app's
                              quiz! Your engagement is truly appreciated. We
                              hope you enjoyed the quiz. Stay tuned with our
                              app!.
                            </p>
                            <div className="w-full flex flex-col">
                              <button
                                onClick={() => {
                                  closeWindow();
                                }}
                                className="mx-5 mt-5 py-2 rounded-md font-bold bg-yellow-400 text-black"
                              >
                                Close
                              </button>
                              {/* <button
                            className={`mx-5 mt-5 py-2 rounded-md font-bold bg-yellow-400 text-black ${
                              unclaimQuiz >= 10 ? "opacity-100" : "opacity-50"
                            }`}
                            onClick={() => {
                              if (unclaimQuiz >= 10) {
                                navigate("/upi");
                                eventLog("WITHDRAW_NOW_CLICK");
                              }
                            }}
                            disabled={unclaimQuiz >= 10 ? false : true}
                          >
                            Claim Now
                          </button> */}
                            </div>
                          </div>
                        )}
                        {/* <p className="flex items-center mt-1 font-semibold text-lg text-yellow-400 bg-yellow-400/20 px-3 py-1 rounded-2xl">
                          <BiRupee />
                          <span>{data?.[0]?.amountUpto ?? ""}</span>
                        </p> */}

                        {/* <div> 
                        <div className="flex justify-between items-center mt-4">
                            <BsInfoCircleFill className="text-base bg-blue-400 rounded-full" />
                            <p className="flex items-center text-[10px] text-[#E7E8E9] ml-2">
                              Claim your Reward above <BiRupee />
                              10
                            </p>
                          </div> 
                         </div> */}
                      </div>
                      {howWorks && (
                        <div className="w-full h-full overflow-hidden">
                          <div
                            className="fixed inset-0 z-10 bg-black opacity-80 h-screen w-screen"
                            style={{
                              zIndex: 1,
                            }}
                            onClick={() => setHowWorks(false)}
                          ></div>
                          <div className="fixed inset-0 w-full h-fit min-h-[80%] max-h-[80%] overflow-y-scroll bg-[#002A67] mt-auto rounded-t-lg z-30">
                            {/* <img
                              src="./img/mask.png"
                              alt=""
                              className="w-full h-full object-contain"
                            /> */}
                            <div className="mx-auto mt-8 flex flex-col justify-between items-center max-w-[500px] w-[90%] z-20">
                              <img
                                src="/img/fireStar.png"
                                alt=""
                                className="mx-auto w-16"
                              />
                              <h2 className="font-bold mx-auto my-6 text-2xl opacity-75">
                                How it works
                              </h2>
                              <div className="flex mr-auto items-center">
                                <p className="text-[35px]">🔥</p>
                                <div className="ml-2">
                                  <p className="font-semibold">Streaks</p>
                                  <p className="text-xs opacity-80">
                                    Keep your streak alive by playing the quiz
                                    every day without missing.
                                  </p>
                                </div>
                              </div>
                              <div className="flex mr-auto mt-2 items-center">
                                <img
                                  src="/img/question.svg"
                                  alt=""
                                  className="ml-2"
                                />
                                <div className="ml-2">
                                  <p className="font-semibold">Streaks Quiz</p>
                                  <p className="text-xs opacity-80">
                                    Maintain streaks continuously for 7 days and
                                    unlock the special Streak Quiz on every
                                    Monday.
                                  </p>
                                </div>
                              </div>
                              <h2 className="font-bold text-2xl my-6 opacity-75">
                                FAQ
                              </h2>
                              <div className="text-left">
                                <div className="">
                                  <p className="font-semibold mb-2">
                                    Can I play Streak Quiz after maintaining
                                    streaks for 7 days?
                                  </p>
                                  <p className="text-xs opacity-80">
                                    Yes, play daily to maintain your streak and
                                    unlock the “Streak Quiz” on every Monday.
                                  </p>
                                </div>
                                <div className="my-4">
                                  <p className="font-semibold mb-2">
                                    If I answer a quiz question wrongly, does it
                                    break my streak?
                                  </p>
                                  <p className="text-xs opacity-80">
                                    No worries! Streaks are about playing every
                                    day, not about correct answers.
                                  </p>
                                </div>
                                <div className="">
                                  <p className="font-semibold mb-2">
                                    What happens if I break the streak?
                                  </p>
                                  <p className="text-xs opacity-80">
                                    If you break the streak, no worries! Start
                                    the streak again by playing daily. Please
                                    remember, the streak lasts for 7 days, so
                                    you have to restart it every week.
                                  </p>
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setHowWorks(false);
                                }}
                                className="w-full mx-5 my-5 py-2 rounded-md font-bold text-lg bg-yellow-400 text-black z-30"
                              >
                                Back
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {popup && (
                        <div className="w-full h-full overflow-hidden">
                          <div
                            className="fixed inset-0 z-10 bg-black opacity-90 h-screen w-screen"
                            style={{
                              zIndex: 1,
                            }}
                            onClick={() => setPopup(false)}
                          ></div>
                          <div
                            className="fixed inset-0 flex items-center justify-center w-full h-fit min-h-[100%] max-h-[100%] overflow-y-scroll z-30"
                            onClick={() => setPopup(false)}
                          >
                            <div className="relative">
                              {/* <AiOutlineCloseCircle
                                className="text-white text-3xl absolute top-[1px] right-[1px]"
                                onClick={() => setPopup(false)}
                              /> */}
                              <img
                                src="./img/quizEndPopup2.png"
                                alt=""
                                className="w-[90vw] max-w-[400px] h-[82vh] rounded-lg object-contain"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {false ? (
                        <div className="mt-5 w-full px-4">
                          <div
                            style={
                              {
                                // backgroundImage: "url(/img/bg.png)",
                              }
                            }
                            className="bg-[#2F1E5A] overflow-hidden rounded-md border-white border-2 py-4 relative flex flex-col items-center"
                          >
                            {/* <img
                          src
                          className="absolute object-cover inset-0 bg-blue-400"
                          style={{
                            zIndex: -10,
                          }}
                        /> */}
                            <p className="text-center text-sm">
                              Participate in Connections Game & Win Exciting
                              prizes daily
                            </p>
                            <video
                              className="mt-5"
                              autoPlay={getOS(window) !== "MacOS"}
                              width="100%"
                              height="360"
                              controls={true}
                              controlsList="nodownload"
                              loop={false}
                              poster="https://d15uzbuto1arv2.cloudfront.net/uploads/2b12e4e6-1889-4abc-b8a5-b2acf9b4393c.png"
                            >
                              <source
                                src="https://vz-2813bccd-3cb.b-cdn.net/ee4c666b-535d-4456-a0dd-5ed0605ae8bf/play_360p.mp4"
                                type="video/mp4"
                              />
                            </video>
                            <a
                              href={
                                A.MODE === "LIVE"
                                  ? "https://pepul-pages-live.vercel.app?redirectTo=/connections&token=" +
                                    user.accessToken
                                  : "https://pepul-pages.vercel.app?redirectTo=/connections&token=" +
                                    user.accessToken
                              }
                              className="w-full flex flex-col"
                            >
                              <button
                                // onClick={() => {
                                //   if (A.MODE === "LIVE")
                                //     window.location.href =
                                //       "https://pepul-pages-live.vercel.app?redirectTo=/connections&token=" +
                                //       user.accessToken;
                                //   else
                                //     window.location.href =
                                //       "https://pepul-pages.vercel.app?redirectTo=/connections&token=" +
                                //       user.accessToken;
                                // }}
                                className="mx-5 mt-3 py-2 rounded-md font-bold bg-yellow-400 text-black"
                              >
                                Play Connections
                              </button>
                            </a>
                          </div>
                        </div>
                      ) : null}
                      {/* <p className="mt-5 text-xs">How to participate</p> */}
                      {/* <div className="px-5">
                      <video
                        className="mt-5 w-full w-[90vw] max-w-[600px] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
                        // autoPlay
                        // muted
                        poster={inviteLoaded?.quizIntroVideoThumbnail}
                        controlsList="nodownload"
                        loop
                        controls
                      >
                        <source
                          src={inviteLoaded?.quizIntroVideo}
                          type="video/mp4"
                        />
                      </video>
                    </div> */}
                      {/* <h1 className="mt-4 font-bold text-md">
                        Play Mini Games
                      </h1>
                      <p className="text-xs opacity-75">
                        (No Rewards Applicable)
                      </p>
                      <div className="px-2 mt-2 w-full flex flex-wrap">
                        {games?.map((each) => (
                          <div className="w-1/3 p-2">
                            <img
                              onClick={() => {
                                if (each.url) {
                                  navigate({
                                    pathname: "/playGame",
                                    search: createSearchParams({
                                      name: each.name,
                                      url: each.url,
                                    }).toString(),
                                  });
                                  eventLog("GAME_CLICK_" + each.url);
                                }
                              }}
                              style={{
                                aspectRatio: 1 / 1,
                              }}
                              className="bg-black rounded-lg w-full object-cover"
                              src={each.image}
                            />
                          </div>
                        ))}
                      </div> */}
                      {/* <p
                      onClick={() => {
                        setShowInfo(true);
                      }}
                      className="mt-4 underline text-xs text-center"
                    >
                      Premium Quiz and Prime Quiz are closed.
                    </p> */}

                      {/* <p
              onClick={() => {
                navigate("/verify");
              }}
              className="underline text-xs text-center mb-4"
            >
              Verify
            </p> */}
                      {/* <RenderFAQ />
                      <p
                        onClick={() => {
                          navigate("/rules");
                        }}
                        className="mt-4 mb-5 underline text-xs text-center"
                      >
                        Terms and Conditions
                      </p> */}
                    </div>
                  ) : (
                    <>
                      {data?.map((each) => (
                        <div
                          onClick={() => {
                            if (each?.amount === 0) eventLog("FREE_CLICK");
                            if (each?.amount === 99) eventLog("PREMIUM_CLICK");
                            if (each?.amount === 999 || each?.amount === 499)
                              eventLog("VIP_CLICK");
                            if (each?.subscribedStatus) {
                              navigate("/checkQuizId/" + each?.planId);
                              // playClick();
                            } else
                              navigate(
                                "/subscribePlan?plan=" +
                                  (each?.amount === 99 ? "PREMIUM" : "VIP")
                              );
                          }}
                          className="rounded-md m-5 p-5 border-2 border-[#91A6C3] bg-gradient-to-l from-[#496F9C] to-[#496F9C]"
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex flex-col">
                              <p>Watch and Win Upto</p>
                              <p className="mt-1 text-yellow-400 font-bold text-2xl flex items-center">
                                <BiRupee />
                                <p className="">
                                  {formatMoney(each?.amountUpto)}
                                </p>
                              </p>
                              <p className="text-yellow-400 font-bold">
                                Everyday
                              </p>
                            </div>

                            <div className="flex flex-col items-end">
                              {/* {each?.amount !== 0 && (
                        <FaInfoCircle
                          onClick={() => {
                            navigate("/subscribePlan?plan=PREMIUM");
                          }}
                          className="mb-5"
                        />
                      )} */}
                              {renderButton(each?.amount, each)}
                            </div>
                          </div>
                          {each?.fakeWinners ? (
                            <div className="flex">
                              <p className="text-xs py-1 px-1 flex items-center mt-2 bg-blue-400 px-2 text-white rounded-full">
                                <AiOutlineTrophy />
                                <p className="ml-1">
                                  {formatMoney(each?.fakeWinners)} Winners Every
                                  Month
                                </p>
                              </p>
                            </div>
                          ) : null}
                          {each?.status === "PRE-BOOKING" && (
                            <>
                              {each?.preBookText && (
                                <div className="flex text-xs mt-2">
                                  <p className="text-red-200">
                                    🔥 {each?.preBookText}
                                  </p>
                                </div>
                              )}
                              {each?.fakePreBooked && (
                                <div className="relative mt-4 h-2">
                                  <div className="absolute rounded-full h-2 w-full bg-blue-500"></div>
                                  <div className="absolute rounded-full h-2 w-[20%] bg-green-500"></div>
                                </div>
                              )}
                              {each?.fakePreBooked && (
                                <p className="text-xs mt-3">
                                  {formatMoney(each?.fakePreBooked)}+ Users
                                  prebooked Prime quiz
                                </p>
                              )}
                            </>
                          )}
                          {/* <div className="mt-3 flex">
                    {each?.amount === 99 ? (
                      <p className="text-xs text-white">
                        <div className="flex items-center">
                          <AiOutlineGift className="text-xl mr-1" />
                          <span className="font-bold mr-1">Rs. 10,000</span>
                        </div>
                        <p className="mt-1">
                          Bonus for one participant every month
                        </p>
                      </p>
                    ) : each?.amount === 999 ? (
                      <p className="text-xs text-white">
                        <div className="flex items-center">
                          <AiOutlineGift className="text-xl mr-1" />
                          <span className="font-bold mr-1">Rs. 1,00,000</span>
                        </div>
                        <p className="mt-1">
                          Bonus for one participant every month
                        </p>
                      </p>
                    ) : null}
                  </div> */}
                        </div>
                      ))}
                      <p
                        onClick={() => {
                          navigate("/rules");
                        }}
                        className="underline text-xs text-center mb-2"
                      >
                        Terms and Conditions
                      </p>
                    </>
                  )}
                  {/* <p
                    onClick={() => {
                      navigate("/verify");
                    }}
                    className="underline text-xs text-center mb-4"
                  >
                    Verify
                  </p> */}
                </FadeIn>
              ) : null
            ) : searchParams.get("page") === "RESULTS" ? (
              <Results />
            ) : searchParams.get("page") === "LEADERBOARD" ? (
              <LeaderBoard />
            ) : null}
          </>
        </div>
      </FadeIn>

      {/* {searchParams.get("page") === "LEADERBOARD" && (
        <div
          className="fixed inset-0 bg-blue-500 h-screen w-screen"
          style={{
            zIndex: -5,
          }}
        ></div>
      )} */}
    </>
  ) : (
    <div className="h-screen w-screen flex flex-col z-10 items-center justify-center">
      {/* <Lottie
        options={{
          animationData: loadingJson,
          loop: true,
        }}
        height={"50vw"}
        width={"50vw"}
      /> */}
      <div className="w-[90vw] max-w-[500px] mx-auto">
        <p className="text-2xl text-center text-yellow-400 font-bold mt-4">
          Pepul Quiz Ends
        </p>
        <p className="text-center text-[12px] mt-2 text-white font-bold">
          <span className="text-3xl">🎉</span> <br />
          Thank you for actively participated in our app's quiz! Your engagement
          is truly appreciated. We hope you enjoyed the quiz. Stay tuned with
          our app!.
        </p>
        <div className="w-full flex flex-col">
          <button
            onClick={() => {
              closeWindow();
            }}
            className="mx-5 mt-5 py-2 rounded-md font-bold bg-yellow-400 text-black"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanHome;

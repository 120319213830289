import React from "react";

import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import ReactPlayer from "react-player";
import ReactHlsPlayer from "../../components/ReactHlsPlayer";
import axios from "axios";

import { FcAlarmClock } from "react-icons/fc";
import { FaRupeeSign } from "react-icons/fa";

import { addSeconds, format } from "date-fns";

import ticketImage from "../../assets/image/tickets.png";

import { BiCheck, BiRupee } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdOutlineOndemandVideo } from "react-icons/md";

import A from "../../utils/API";

import useLang from "../../hooks/useLang";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import { IoIosArrowBack } from "react-icons/io";
import { BsCheckLg, BsPlus } from "react-icons/bs";
import VideoNotViewed from "../../components/VideoNotViewed";

const Timer = React.memo(function ({
  quizType = null,
  startTime = new Date(),
  onComplete = () => {},
  onParticipateClick = () => {},
  showTimer = true,
}) {
  const navigate = useNavigate();
  const { lang, switchLang, activeLang } = useLang();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      // Render a countdown
      return (
        <div className="ml-2 flex flex-col items-center">
          {showTimer ? (
            <div className="items-center flex gap-x-2 my-2">
              <FcAlarmClock className="text-2xl" />
              <p className="text-yellow-500">
                <div className="flex items-center flex-col">
                  {!Number.isNaN(minutes) && <p>{zeroPad(minutes)}</p>}
                  {/* <p className="text-xs text-white opacity-50">{lang?.MINUTES}</p> */}
                </div>
              </p>
              <p>:</p>
              <p className="text-yellow-500">
                <div className="flex items-center flex-col">
                  {!Number.isNaN(seconds) && <p>{zeroPad(seconds)}</p>}
                  {/* <p className="text-xs text-white opacity-50">{lang?.SECONDS}</p> */}
                </div>
              </p>
            </div>
          ) : null}
          {/* <p className="mt-2 text-xs font-medium">
            {lang?.DAILY_QUIZ} <b>8:00 pm to 8:05 pm</b>
          </p> */}
        </div>
      );
    }
  };
  return (
    <div className="text-2xl font-bold flex gap-x-4">
      <Countdown onComplete={onComplete} date={startTime} renderer={renderer} />
    </div>
  );
});

const VideoPlayer = React.memo(function ({ src = "", thumbnail = "" }) {
  // const [url, setUrl] = React.useState(src);
  const { getOS } = useUtils();

  // React.useEffect(() => {
  //   setUrl(src);
  // }, [src]);

  return (
    <ReactHlsPlayer
      className="mt-5 w-full w-[95vw] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
      src={src}
      poster={thumbnail}
      autoPlay={getOS(window) === "MacOS" ? false : true}
      // controls={getOS(window) === "MacOS"}
      controls={true}
      width="100%"
      style={{
        aspectRatio: 16 / 9,
      }}
    />
    // <video
    //   className="mt-5 w-full w-[95vw] bg-zinc-400 object-cover min-h-[100px] border-2 rounded-md"
    //   controls={false}
    //   autoPlay={true}
    //   muted={false}
    //   playsInline={true}
    // >
    //   <source src={url} type="application/x-mpegURL" />
    // </video>
  );
});

const VideoWithQuiz = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { headers } = useUser();
  const { activeLang } = useLang();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    closeWindow,
    eventLog,
    addSuffix,
    playClick,
    exitConfirm,
    shuffle,
    openCreator,
  } = useUtils();

  const [showType, setShowType] = React.useState("VIDEO");

  const [selectedAnswer, setSelectedAnswer] = React.useState(false);

  const [didNotWatchVideo, setDidNotWatchVideo] = React.useState(false);

  const [data, setData] = React.useState(false);

  const [quizOpen, setQuizOpen] = React.useState(false);

  const [opacity, setOpacity] = React.useState(0);

  const startDate = React.useRef(Date.now());

  const [correctAnswer, setCorrectAnswer] = React.useState(null);

  const [answerLoading, setAnswerLoading] = React.useState(false);

  const [quizStartTime, setQuizStartTime] = React.useState(false);

  const [selectedQuiz, setSelectedQuiz] = React.useState(false);
  const [availableQuiz, setAvailableQuiz] = React.useState(false);
  const [completedQuiz, setCompletedQuiz] = React.useState(false);

  const [answerTypes, setAnswerTypes] = React.useState(false);

  const [subscriptionStatus, setSubscritionStatus] = React.useState(null);

  const [showPopUp, setShowPopUp] = React.useState(false);

  const [showAd, setShowAd] = React.useState(false);

  const [timer, setTimer] = React.useState(false);

  const fetchQuiz = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.START_QUIZ_MULTI,
        {
          quizId: id,
          langCode: activeLang,
        },
        headers
      );
      setData(data);
      setAnswerTypes(data?.completedQuiz);
      if (!data?.ad?.videoUrl) setShowAd(false);
    } catch (err) {
      console.log(err, null, 4);
      closeWindow();
    }
  };

  const subscribeCreator = async (creatorId) => {
    try {
      const { data } = await axios.post(
        A.HOST + A.SUBSCRIBE_CREATOR,
        {
          creatorId,
        },
        headers
      );
      setSubscritionStatus(true);
      if (quizOpen === false)
        setShowPopUp(`Subscription Success 🎉\n
      Now you can watch all the exclusive videos of this creator for 10 months`);
    } catch (err) {
      if (err?.response?.data?.message === "NOT_ENOUGH_COINS")
        setShowPopUp(`Please purchase Pepul coins to subscribe to this creator's channel
       and watch exclusive videos.`);
      if (err?.response?.data?.message === "ALREADY_SUBSCRIBED") {
        if (quizOpen === false)
          setShowPopUp(`Subscription Success 🎉\n
      Now you can watch all the exclusive videos of this creator for 10 months`);
        setSubscritionStatus(true);
      }
      if (err?.response?.data?.message === "CANNOT_SUBSCRIBE_YOURSELF")
        setShowPopUp(`Sorry, You cannot subscribe yourself`);
    }
  };

  React.useEffect(() => {
    if (data) {
      if (data?.completedQuiz)
        setCompletedQuiz(data?.completedQuiz?.map((each) => each?.postId));
    }
  }, [data]);

  React.useEffect(() => {
    if (data && Array.isArray(completedQuiz)) {
      setAvailableQuiz(
        data?.questions?.filter(
          (each) => !completedQuiz?.includes(each?.videoId)
        )
      );
    }
  }, [data, completedQuiz]);

  React.useEffect(() => {
    if (Array.isArray(availableQuiz)) {
      if (availableQuiz?.length === 0) {
        navigate("/videoResult/" + id, {
          replace: true,
        });
      }
      setSelectedQuiz(availableQuiz?.[0]);

      setSubscritionStatus(availableQuiz?.[0]?.subscriptionStatus);
    }
  }, [availableQuiz]);

  React.useEffect(() => {
    if (selectedQuiz) {
      setShowType("VIDEO");

      console.log(JSON.stringify(selectedQuiz, null, 4));
    }
  }, [selectedQuiz]);

  React.useEffect(() => {
    fetchQuiz();
  }, []);

  const initiateAnswer = async (answer) => {
    setAnswerLoading(true);
    setSelectedAnswer(answer);

    try {
      let time = (new Date() - quizStartTime) / 1000;

      const condition = {
        quizId: id,
        postId: selectedQuiz?.videoId,
        answer: answer,
        langCode: activeLang,
        quizTime: time,
      };

      if (searchParams.get("ticketId")) {
        condition["ticketId"] = searchParams.get("ticketId");
      }

      const { data } = await axios.post(
        A.HOST + A.ANSWER_QUIZ_MULTI,
        condition,
        headers
      );
      setQuizOpen(false);

      if (data?.completedQuiz) {
        // setAnswerLoading(true);
        setCorrectAnswer(data?.rightStatus);
        setTimeout(() => {
          startDate.current = new Date();
          setAnswerLoading(false);
          setSelectedAnswer(false);
          setCorrectAnswer(null);
          setCompletedQuiz(data?.completedQuiz?.map((each) => each?.postId));
          setAnswerTypes(data?.completedQuiz);
        }, 800);
      }
    } catch (err) {
      console.log(err, null, 4);
      if (err?.response?.data?.message === "NOT_WATCH_FULL_VIDEO") {
        setDidNotWatchVideo(true);
        setAnswerLoading(false);
        setSelectedAnswer(false);
        setCorrectAnswer(null);
        return;
      }
      closeWindow();
    }
    // setAnswerLoading(false);
  };

  // React.useEffect(() => {
  //   exitConfirm();
  // }, []);

  // React.useEffect(() => {
  //   const closeWindow = () =>
  //     setTimeout(() => {
  //       window.open("about:blank", "_self");
  //       window.close();
  //     }, 3000);
  //   closeWindow();
  // }, []);

  React.useEffect(() => {
    if (data !== false)
      setTimeout(() => {
        setOpacity(1);
      }, 200);
  }, [data]);

  const putVideoView = async (postId) => {
    try {
      const { data } = await axios.post(
        A.HOST + A.VIEW_QUIZ_VIDEO,
        {
          quizId: id,
          postId: postId,
        },
        headers
      );
    } catch (er) {}
  };

  React.useEffect(() => {
    if (showType === "VIDEO" && selectedQuiz) {
      putVideoView(selectedQuiz?.videoId);
    }
  }, [showType, selectedQuiz]);

  return (
    <>
      {showAd ? (
        <div
          className="h-screen w-screen transition duration-500 ease-out flex flex-col z-10"
          style={{
            opacity: opacity,
          }}
        >
          <IoIosArrowBack
            onClick={() => {
              navigate(-1);
            }}
            className="mt-5 mx-4 fixed text-4xl"
          />
          <h1 className="opacity-0 mt-5 font-bold text-xl text-center">
            Question {completedQuiz?.length + 1}
          </h1>
          <div className="mt-5 text-xl flex justify-center items-center">
            <div className="h-full max-w-[90vw] overflow-x-scroll scroll whitespace-nowrap">
              <div className="inline-block align-middle">
                <div className="flex items-center">
                  <h1
                    style={{
                      opacity: 1,
                    }}
                    className={`text-black rounded-md font-bold text-sm py-1 px-3 text-lg bg-yellow-500`}
                  >
                    <MdOutlineOndemandVideo className="text-xl" />
                  </h1>
                  <div
                    style={{
                      opacity: 0.3,
                    }}
                    className="h-1 w-[15px] bg-white"
                  ></div>
                </div>
              </div>
              {data?.questions?.map((each, idx) => (
                <div className="inline-block align-middle">
                  <div className="flex items-center">
                    <h1
                      style={{
                        opacity: 0.3,
                      }}
                      className={`text-black rounded-md font-bold text-sm py-1 px-3 text-lg ${
                        completedQuiz?.length > idx
                          ? answerTypes?.[idx]?.answerType === "RIGHT"
                            ? "bg-yellow-500"
                            : "bg-yellow-500"
                          : // ? "bg-green-500 text-white"
                            // : "bg-red-500 text-white"
                            "bg-yellow-500"
                      }`}
                    >
                      {completedQuiz?.length > idx ? (
                        <>
                          {answerTypes?.[idx]?.answerType !== "RIGHT" ? (
                            //  <ImCross className="text-md my-1" />
                            <BsCheckLg className="text-sm my-1" />
                          ) : (
                            <BsCheckLg className="text-sm my-1" />
                          )}
                        </>
                      ) : (
                        <p className="text-sm">{idx + 1}</p>
                      )}
                    </h1>
                    <div
                      style={{
                        opacity: 0.3,
                      }}
                      className="h-1 w-[15px] bg-white"
                    ></div>
                  </div>
                </div>
              ))}
              <img
                style={{
                  opacity: 0.5,
                }}
                className="inline-block h-10 min-w-[30px] object-cover"
                src="/img/coins1.gif"
                alt=""
              />
            </div>
          </div>
          {showType === "VIDEO" ? (
            <div className="flex h-full justify-center flex-col w-full items-center">
              {searchParams.get("ticketId") ? (
                <div className="mb-5 text-xs bg-blue-500 rounded-full px-3 py-1 flex items-center">
                  <p className="text-yellow-400">Golden Ticket applied</p>
                  <img className="h-5 ml-2" src={ticketImage} alt="" />
                </div>
              ) : null}
              <p className="mt-4 text-md">Quiz will start after the video</p>
              <VideoPlayer
                thumbnail={
                  "https://d15uzbuto1arv2.cloudfront.net/uploads/8f16338a-6572-4370-9c5c-ebde0b3ea9c6.png"
                }
                // thumbnail={selectedQuiz?.videoThumbnail}
                src={data?.ad?.videoUrl}
              />
              <div className="flex items-center justify-between w-full px-5 mt-3"></div>
              <div className="text-lg flex flex-col items-center">
                <Timer
                  quizType={showType}
                  showTimer={data?.showTimer ?? false}
                  onComplete={() => {
                    setShowAd(false);
                  }}
                  startTime={addSeconds(startDate.current, 30)}
                />
                {/* <p className="ml-1 font-bold">{}</p> */}
              </div>
              <button
                onClick={() => {
                  openCreator(data?.ad?.creatorId);
                  eventLog("AD_BANNER_CLICK");
                }}
                className="mt-5 rounded-md w-[80vw] bg-yellow-500 text-black text-sm md:text-lg font-bold py-3"
              >
                Watch My Channel
              </button>
              {/* {quizOpen && (
                
              )} */}
            </div>
          ) : (
            <div className="mt-10 flex justify-center flex-col w-full items-center">
              <div className="py-2">
                <h3 className="max-w-[600px] text-center indent-3 text-lg rounded-xl bg-white text-black font-bold mx-5 p-4">
                  {selectedQuiz?.question}
                </h3>
              </div>
              <div className="flex flex-col items-center w-full max-w-[600px] mx-3 px-5 pt-4 text-xl font-bold">
                {selectedQuiz?.answers?.map((each) => (
                  <button
                    onClick={() => {
                      if (answerLoading === false) {
                        initiateAnswer(each);
                        eventLog("ANSWER_CLICK", id);
                        playClick();
                      }
                    }}
                    disabled={answerLoading}
                    className="border rounded-md w-full px-3 h-14 my-2 pl-4 text-left"
                    style={{
                      backgroundColor:
                        correctAnswer === true && selectedAnswer === each
                          ? "" //"green"
                          : correctAnswer === false && selectedAnswer === each
                          ? "" //"red"
                          : undefined,
                      opacity:
                        answerLoading === true && selectedAnswer !== each
                          ? 0.5
                          : 1,
                    }}
                  >
                    {each}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : null}
      {didNotWatchVideo ? (
        <VideoNotViewed
          onClose={() => {
            navigate("/checkQuizId/" + data?.planDetails?.quizSubscriptionId);
          }}
        />
      ) : null}
      {showAd === false && showPopUp && quizOpen === false ? (
        <div className="fixed flex justify-center items-center inset-0 z-20">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="flex flex-col items-center max-w-[85vw] rounded-md text-black bg-white p-3 z-10">
            <span
              style={{
                whiteSpace: "pre-line",
              }}
              className="text-center"
            >
              {showPopUp}
            </span>
            <button
              onClick={() => setShowPopUp(false)}
              className="font-bold mt-4 px-8 rounded-md py-2 bg-blue-800 text-white"
            >
              Ok
            </button>
          </div>
        </div>
      ) : null}
      {showAd === false && data !== false && completedQuiz !== false && (
        <div
          className="h-screen w-screen transition duration-500 ease-out flex flex-col z-10"
          style={{
            opacity: opacity,
          }}
        >
          <IoIosArrowBack
            onClick={() => {
              navigate("/home");
            }}
            className="mt-5 mx-4 fixed text-4xl"
          />
          <h1 className="mt-5 font-bold text-xl text-center">
            Question {completedQuiz?.length + 1}
          </h1>
          <div className="mt-5 text-xl flex justify-center items-center">
            <div className="h-full max-w-[90vw] overflow-x-scroll scroll whitespace-nowrap">
              {/* <div className="inline-block align-middle">
                <div className="flex items-center">
                  <h1
                    style={{
                      opacity: 1,
                    }}
                    className={`text-black rounded-md font-bold text-sm py-1 px-3 text-lg bg-yellow-500`}
                  >
                    <MdOutlineOndemandVideo className="text-xl" />
                  </h1>
                  <div
                    style={{
                      opacity: 1,
                    }}
                    className="h-1 w-[15px] bg-white"
                  ></div>
                </div>
              </div> */}
              {data?.questions?.map((each, idx) => (
                <div className="inline-block align-middle">
                  <div className="flex items-center">
                    <h1
                      style={{
                        opacity: completedQuiz?.length >= idx ? 1 : 0.3,
                      }}
                      className={`text-black rounded-md font-bold text-sm py-1 px-3 text-lg ${
                        completedQuiz?.length > idx
                          ? answerTypes?.[idx]?.answerType === "RIGHT"
                            ? "bg-yellow-500"
                            : "bg-yellow-500"
                          : // ? "bg-green-500 text-white"
                            // : "bg-red-500 text-white"
                            "bg-yellow-500"
                      }`}
                    >
                      {completedQuiz?.length > idx ? (
                        <>
                          {answerTypes?.[idx]?.answerType !== "RIGHT" ? (
                            //  <ImCross className="text-md my-1" />
                            <BsCheckLg className="text-sm my-1" />
                          ) : (
                            <BsCheckLg className="text-sm my-1" />
                          )}
                        </>
                      ) : (
                        <p className="text-sm">{idx + 1}</p>
                      )}
                    </h1>
                    <div
                      style={{
                        opacity: completedQuiz?.length > idx ? 1 : 0.3,
                      }}
                      className="h-1 w-[15px] bg-white"
                    ></div>
                  </div>
                </div>
              ))}
              <img
                style={{
                  opacity: 0.5,
                }}
                className="inline-block h-10 min-w-[30px] object-cover"
                src="/img/coins1.gif"
                alt=""
              />
            </div>
          </div>
          {showType === "VIDEO" ? (
            <div className="flex h-full justify-center flex-col w-full items-center">
              {searchParams.get("ticketId") ? (
                <div className="mb-5 text-xs bg-blue-500 rounded-full px-3 py-1 flex items-center">
                  <p className="text-yellow-400">Golden Ticket applied</p>
                  <img className="h-5 ml-2" src={ticketImage} alt="" />
                </div>
              ) : null}
              <div className="mb-2 flex justify-center items-center flex-col">
                <p className="text-xl md:text-2xl font-bold uppercase">
                  Watch & Get a Chance
                </p>
                <p className="text-md font-bold mt-1">to win upto</p>
                <p className="mt-2 font-bold text-2xl lg:text-4xl text-yellow-400">
                  ₹ {data?.planDetails?.amountUpto}
                </p>
              </div>
              <p>
                {data?.questions?.length - completedQuiz?.length} Questions
                Remaining to Withdraw Money
              </p>
              <VideoPlayer
                thumbnail={selectedQuiz?.videoThumbnail}
                src={selectedQuiz?.videoUrl}
              />
              <div className="flex items-center justify-between w-full px-5 mt-3">
                {/* <div className="flex items-center">
                  <div className="relative h-12 w-12">
                    <img
                      className="absolute h-12 w-12 bg-blue-800 inset-0 border-2 border-yellow-500 object-cover rounded-md"
                      src={selectedQuiz?.creatorImage}
                      alt=""
                    />
                    <img
                      className="absolute z-10 right-0 -m-1 top-0"
                      src="/img/star.png"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    {selectedQuiz?.creatorName && (
                      <p className="-mt-1 text-sm font-bold">
                        {selectedQuiz?.creatorName?.substring(0, 30)}
                      </p>
                    )}
                    {selectedQuiz?.creatorCategory && (
                      <div className="flex">
                        <p
                          className="text-xs px-2 py-1"
                          style={{
                            fontSize: 10,
                            borderWidth: 1,
                          }}
                        >
                          {selectedQuiz?.creatorCategory}
                        </p>
                      </div>
                    )}
                  </div>
                </div> */}
                {/* {subscriptionStatus !== null && (
                  <div className="">
                    {subscriptionStatus ? (
                      <div className="flex items-center rounded-full px-2 py-1 md:py-2  md:px-4 bg-blue-800">
                        <BiCheck className="text-2xl" />
                        <p>Subscribed</p>
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          subscribeCreator(selectedQuiz?.creatorId)
                        }
                        className="flex items-center rounded-full px-2 py-1 md:py-2  md:px-4 bg-green-500"
                      >
                        <BsPlus className="text-2xl" />
                        <p>Subscribe</p>
                      </div>
                    )}
                  </div>
                )} */}
              </div>
              {/* <p
                className="p-3 mt-5 bg-blue-600 text-center w-full font-bold"
                style={{
                  backgroundColor: "#325888",
                }}
              >
                {selectedQuiz?.videoTitle}
              </p> */}
              {quizOpen === false && (
                <div className="text-lg flex flex-col items-center">
                  <p className="mt-4 text-xs">
                    Question will be revealed after watching the video
                  </p>

                  <Timer
                    quizType={showType}
                    showTimer={data?.showTimer ?? false}
                    onComplete={() => {
                      setQuizOpen(true);
                      setSubscritionStatus(null);
                      eventLog("TIMER_COMPLETE", id);
                      // setShowType("QUIZ");
                      // setQuizStartTime(new Date());
                      // eventLog("TAKE_QUIZ", id);
                    }}
                    startTime={addSeconds(
                      startDate.current,
                      selectedQuiz?.videoSeconds
                    )}
                  />
                  {/* <p className="ml-1 font-bold">{}</p> */}
                </div>
              )}
              <button
                onClick={() => {
                  if (quizOpen) {
                    setShowType("QUIZ");
                    setQuizStartTime(new Date());
                    eventLog("TAKE_QUIZ", id);
                  }
                }}
                style={{
                  opacity: quizOpen ? 1 : 0.5,
                }}
                className="mt-5 rounded-md w-[80vw] bg-yellow-500 text-black text-sm md:text-lg font-bold py-3"
              >
                Reveal question
              </button>
              {/* {quizOpen && (
                
              )} */}
            </div>
          ) : (
            <div className="mt-10 flex justify-center flex-col w-full items-center">
              <div className="py-2">
                <h3 className="max-w-[600px] text-center indent-3 text-lg rounded-xl bg-white text-black font-bold mx-5 p-4">
                  {selectedQuiz?.question}
                </h3>
              </div>
              <div className="flex flex-col items-center w-full max-w-[600px] mx-3 px-5 pt-4 text-xl font-bold">
                {selectedQuiz?.answers?.map((each) => (
                  <button
                    onClick={() => {
                      if (answerLoading === false) {
                        initiateAnswer(each);
                        eventLog("ANSWER_CLICK", id);
                        playClick();
                      }
                    }}
                    disabled={answerLoading}
                    className="border rounded-md w-full px-3 h-14 my-2 pl-4 text-left"
                    style={{
                      backgroundColor:
                        correctAnswer === true && selectedAnswer === each
                          ? "" //"green"
                          : correctAnswer === false && selectedAnswer === each
                          ? "" //"red"
                          : undefined,
                      opacity:
                        answerLoading === true && selectedAnswer !== each
                          ? 0.5
                          : 1,
                    }}
                  >
                    {each}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {/* <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      /> */}
    </>
  );
};

export default VideoWithQuiz;

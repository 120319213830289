import React from "react";

import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FadeIn from "../../components/FadeIn";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function PlayGame() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = React.useState(true);

  const [showLoading, setShowLoading] = React.useState(true);

  React.useEffect(() => {
    if (loading === false)
      setTimeout(() => {
        setShowLoading(false);
      }, 500);
  }, [loading]);

  return (
    <FadeIn>
      <div className="h-screen flex flex-col">
        <div
          onClick={() => {
            // navigate("/home", {
            //   replace: true,
            // });
            navigate(-1);
          }}
          className="flex items-center bg-blue-900 p-2"
        >
          <IoIosClose className="text-4xl" />
        </div>
        {showLoading ? (
          <div
            className="absolute inset-0 bg-black transition duration-500"
            style={{
              opacity: loading ? 1 : 0,
            }}
          >
            <div className="h-full flex justify-center items-center">
              <AiOutlineLoading3Quarters className="text-3xl animate-spin" />
              <p className="ml-2">Loading</p>
            </div>
          </div>
        ) : null}
        <iframe
          onLoad={() => {
            setLoading(false);
          }}
          className="h-full w-full"
          src={searchParams.get("url")}
          frameborder="0"
        ></iframe>
      </div>
    </FadeIn>
  );
}

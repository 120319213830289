import React from "react";
import axios from "axios";

import { Howl, Howler } from "howler";

import A from "../utils/API";

import useUser from "./useUser";
import { format } from "date-fns";

export default function useUtils() {
  const { headers } = useUser();

  const [sound, setSound] = React.useState(false);

  React.useState(() => {
    const tmp = new Howl({
      src: ["/sounds/click.mp3"],
    });
    setSound(tmp);
  }, []);

  const playWinAmount = () => {
    const tmp = new Howl({
      src: ["/sounds/win-amount.mp3"],
    });
    tmp?.play();
  };

  const closeWindow = () => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "CLOSE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "CLOSE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
  };

  const shareScreenshot = (text = "") => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "SCREENSHOT_SHARE",
        videoId: 0,
        text: text,
        url: text,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "SCREENSHOT_SHARE",
        videoId: 0,
        text: text,
        url: text,
      })
    );
  };

  const protect = function (string) {
    const upi = string;
    let final = "";
    const text = upi.split("@");
    text[0].split("").forEach((each, idx) => {
      final = final + (idx > 2 ? "*" : each);
    });
    final += "@";
    final += text[1];
    return final;
  };

  const openVerify = (text = "") => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "VERIFY",
        videoId: 0,
        text: text,
        url: text,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "VERIFY",
        videoId: 0,
        text: text,
        url: text,
      })
    );
  };

  const openComplete = (text = "") => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "COMPLETE",
        videoId: 0,
        text: text,
        url: text,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "COMPLETE",
        videoId: 0,
        text: text,
        url: text,
      })
    );
  };

  const openUrl = (url = "") => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "OPEN_URL",
        videoId: 0,
        text: "",
        url: url,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "OPEN_URL",
        videoId: 0,
        text: "",
        url: url,
      })
    );
  };

  const saveScreenshot = () => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "SCREENSHOT_SAVE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "SCREENSHOT_SAVE",
        videoId: 0,
        text: "",
        url: "",
      })
    );
  };

  const exitConfirm = () => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "EXIT_CONFIRM",
        videoId: 0,
        text: "Are you sure to close ?",
        url: "",
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "EXIT_CONFIRM",
        videoId: 0,
        text: "Are you sure to close ?",
        url: "",
      })
    );
  };

  const openUser = (userId = 71622728) => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "OPEN_USER",
        videoId: 0,
        text: "",
        url: "",
        userId: userId,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "OPEN_USER",
        videoId: 0,
        text: "",
        url: "",
        userId: userId,
      })
    );
  };

  const openCreator = (creatorId = 76718976) => {
    window?.JSBridge?.showMessageInNative(
      JSON.stringify({
        type: "OPEN_CREATOR",
        videoId: 0,
        text: "",
        url: "",
        creatorId: creatorId,
      })
    );
    window?.webkit?.messageHandlers?.messageObserver?.postMessage(
      JSON.stringify({
        type: "OPEN_CREATOR",
        videoId: 0,
        text: "",
        url: "",
        creatorId: creatorId,
      })
    );
  };

  const eventLog = async (name = "", quizId = null) => {
    try {
      const { data } = await axios.post(
        A.HOST + A.QUIZ_LOG,
        {
          eventName: name,
          quizId,
        },
        headers
      );
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const formatMoney = (money) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      // style: "currency",
      // currency: "INR",
    });
    return formatter.format(money);
  };

  const playClick = () => {
    sound?.play();
  };

  const playSuccess = () => {
    const sound = new Howl({
      src: ["/sounds/win.wav"],
    });
    sound.play();
  };

  const addSuffix = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const getOS = (window) => {
    let operatingSystem = "Not known";
    if (window?.navigator?.appVersion?.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    }
    if (window?.navigator?.appVersion?.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window?.navigator?.appVersion?.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window?.navigator?.appVersion?.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }

    return operatingSystem;
  };

  const renderDate = (date) =>
    new Date().getMonth() === new Date(date)?.getMonth() &&
    new Date().getDate() - new Date(date).getDate() === 0
      ? "Live"
      : new Date().getMonth() === new Date(date)?.getMonth() &&
        new Date().getDate() - new Date(date).getDate() === 1
      ? "Yesterday"
      : format(new Date(date), "PP");

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  return {
    closeWindow,
    eventLog,
    shareScreenshot,
    openUrl,
    saveScreenshot,
    addSuffix,
    exitConfirm,
    getOS,
    playClick,
    playSuccess,
    formatMoney,
    openVerify,
    openComplete,
    openUser,
    playWinAmount,
    renderDate,
    openCreator,
    shuffle,
    protect,
  };
}

import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";

import A from "../../utils/API";

import FadeIn from "../../components/FadeIn";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

export default function SubscribePlan() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { exitConfirm, eventLog, getOS } = useUtils();
  const { headers, user } = useUser();

  const [loading, setLoading] = React.useState(false);

  const [paymentSuccessLoading, setPaymentSuccessLoading] =
    React.useState(false);

  const [subscriptions, setSubscriptions] = React.useState(false);

  const [showSuccess, setSuccess] = React.useState(false);
  const [paymentResponse, setPaymentResponse] = React.useState(false);

  const [points, setPoints] = React.useState({
    premium: [
      {
        img: "/img/subscribe/Ad free.svg",
        heading: "Ad-free",
        content: "Watch Videos Uninterrupted by Ads",
      },
      {
        img: "/img/subscribe/PIP.svg",
        heading: "Picture in picture Play",
        content: "Play videos while using other apps in PIP Mode.",
      },
      {
        img: "/img/subscribe/Background play.svg",
        heading: "Background Play",
        content: "You can hear audio only when the screen is locked.",
      },
      {
        img: "/img/subscribe/PArticipate.svg",
        heading: (
          <>
            <span className="text-yellow-400">Free</span> to Participate in
            <span className="text-yellow-400"> ₹ 3000</span> quiz for 30 days
          </>
        ),
        content: (
          <>
            Get a chance to participate and win upto Rs.3000 daily for{" "}
            <span className="text-yellow-400">30 days</span>.
          </>
        ),
      },
      {
        img: "/img/subscribe/Pepul coins.svg",
        heading: "Get free 1000 Pepul coins",
        content:
          "Using 1000 pepul coin you can subscribe to one of your favourite pepul creators and watch exclusive content.",
      },
      {
        img: "/img/subscribe/Community.svg",
        heading: "Get exclusive Pepul community benefits",
        content:
          "Get exclusive updates on Pepul community events and get goodies, prizes and more benefits.",
      },
    ],
    vip: [
      {
        img: "/img/subscribe/Ad free.svg",
        heading: "Ad-free",
        content: "Watch Videos Uninterrupted by Ads",
      },
      {
        img: "/img/subscribe/PIP.svg",
        heading: "Picture in picture Play",
        content: "Play videos while using other apps in PIP Mode.",
      },
      {
        img: "/img/subscribe/Background play.svg",
        heading: "Background Play",
        content: "You can hear audio only when the screen is locked.",
      },
      {
        img: "/img/subscribe/PArticipate.svg",
        heading: (
          <>
            <span className="text-yellow-400">Free</span> to Participate in
            <span className="text-yellow-400"> ₹ 10,000</span> quiz for 30 days
          </>
        ),
        content: (
          <>
            Get a chance to participate and win upto Rs.10,000 daily for{" "}
            <span className="text-yellow-400">30 days</span>.
          </>
        ),
      },
      {
        img: "/img/subscribe/Pepul coins.svg",
        heading: "Get free 10,000 Pepul coins",
        content:
          "Using 1000 pepul coin you can subscribe to one of your favourite pepul creators and watch exclusive content.",
      },
      {
        img: "/img/subscribe/Community.svg",
        heading: "Get exclusive Pepul community benefits",
        content:
          "Get exclusive updates on Pepul community events and get goodies, prizes and more benefits.",
      },
    ],
  });

  const initiateSuccess = async (response) => {
    setPaymentSuccessLoading(true);
    try {
      const { data } = await axios.post(
        A.HOST + A.PAYMENT_SUCCESS,
        {
          paymentId: response?.razorpay_payment_id,
          orderId: response?.razorpay_order_id,
        },
        headers
      );
      setSuccess(true);
      fetchSubscriptions();
    } catch (err) {
      console.log(err);
    }
    setPaymentSuccessLoading(false);
  };

  React.useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_ALREADY_SUBSCRIBED,
        {},
        headers
      );
      setSubscriptions(data);
    } catch (er) {}
  };

  const initiatePayment = async (planId = 0) => {
    try {
      // exitConfirm();
      setLoading(true);
      const { data } = await axios.post(
        A.HOST + A.INITIATE_PAYMENT,
        {
          planId,
        },
        headers
      );
      var options = {
        key: data?.key, // Enter the Key ID generated from the Dashboard
        amount: data?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Pepul Tech Private Limited",
        // description: "Test Transaction",
        image: "https://pepul.com/thumbnail-logo.png",
        order_id: data?.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          name: data?.userName,
          email: "quiz@pepul.com",
          contact: data?.phoneNumber,
        },
        handler: function (response) {
          initiateSuccess(response);
        },
        notes: {
          userId: user?.userId,
          planId: planId,
          purchaseId: data?.purchaseId,
          phoneNumber: data?.phoneNumber,
          phoneCode: data?.phoneCode,
        },
        theme: {
          color: "#101826",
        },
        config: {
          display: {
            hide:
              getOS(window) === "MacOS"
                ? [
                    {
                      method: "netbanking",
                    },
                    {
                      method: "wallet",
                    },
                    {
                      method: "paylater",
                    },
                    {
                      method: "upi",
                    },
                  ]
                : [
                    {
                      method: "netbanking",
                    },
                    {
                      method: "wallet",
                    },
                    {
                      method: "paylater",
                    },
                  ],
            preferences: {
              show_default_blocks: true, // Should Checkout show its default blocks?
            },
          },
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (err) {}
    setLoading(false);
  };

  React.useEffect(() => {
    navigate("/home?page=QUIZ", {
      replace: true,
    });
  }, []);

  return (
    <>
      {subscriptions !== false ? (
        <FadeIn>
          <div className="flex flex-col justify-center items-center">
            <div className="mt-2 mb-2 flex items-center justify-between w-full">
              <IoIosArrowBack
                onClick={() => {
                  navigate(-1);
                }}
                className="m-2 mb-1 text-4xl"
              />
            </div>
            {(showSuccess || paymentSuccessLoading) && (
              <FadeIn>
                <div className="z-10 fixed flex justify-center items-center inset-0 bg-black bg-opacity-75">
                  {showSuccess && (
                    <FadeIn>
                      <div className="h-[75vh] text-white flex flex-col w-[80vw] bg-blue-900 text-black p-4 rounded-sm justify-between items-center flex-col">
                        <div className="flex w-full justify-end">
                          <AiFillCloseCircle
                            onClick={() => {
                              setSuccess(false);
                              navigate(-1);
                            }}
                            className="text-2xl text-gray-400"
                          />
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <img
                            className="h-32 mb-2"
                            src="/img/success.gif"
                            alt=""
                          />
                          <p className="text-center">
                            You've successfully{" "}
                            {searchParams.get("plan") === "PREMIUM"
                              ? "purchased"
                              : "purchased"}{" "}
                            the <br />
                            <b>
                              {searchParams.get("plan") === "PREMIUM"
                                ? "Premium Plan"
                                : "Prime Plan"}
                            </b>{" "}
                            for 1 month!
                          </p>
                          {searchParams.get("plan") === "PREMIUM" ||
                          searchParams.get("plan") === "VIP" ? (
                            <p className="mt-5 text-white text-center">
                              Start enjoying the quiz and win up to <br />
                            </p>
                          ) : null}
                          <div className="mt-1 flex flex-col justify-center items-center">
                            <p className="text-3xl text-yellow-400 font-bold">
                              {searchParams.get("plan") === "PREMIUM"
                                ? "Rs. 1000"
                                : "Rs. 10,000"}{" "}
                            </p>
                            <p>daily!</p>
                          </div>
                        </div>
                        <div className="w-full flex flex-col">
                          <button
                            onClick={() =>
                              navigate(
                                searchParams.get("plan") === "PREMIUM"
                                  ? "/checkQuizId/" + 663557
                                  : "/checkQuizId/" + 441843,
                                {
                                  replace: true,
                                }
                              )
                            }
                            className="text-black font-bold rounded-md w-[100%] bg-yellow-400 px-5 mt-2 py-2"
                          >
                            Take Quiz
                          </button>
                          <p
                            onClick={() => {
                              setSuccess(false);
                              navigate(-1);
                            }}
                            className="w-full text-center mt-2 underline"
                          >
                            Later
                          </p>
                        </div>
                      </div>
                    </FadeIn>
                  )}
                </div>
              </FadeIn>
            )}
            {searchParams.get("plan") === "PREMIUM" ? (
              <img className="h-28" src="/img/premium.svg" alt="" />
            ) : searchParams.get("plan") === "VIP" ? (
              <img className="h-28" src="/img/vip.svg" alt="" />
            ) : null}
            {searchParams.get("plan") === "PREMIUM" ? (
              <h1 className="text-xl text-yellow-400 font-bold mt-2">
                GO PREMIUM NOW
              </h1>
            ) : searchParams.get("plan") === "VIP" ? (
              <h1 className="text-xl text-yellow-400 font-bold mt-2">
                GO Prime NOW
              </h1>
            ) : null}
            {/* <pre className="w-full">{JSON.stringify(paymentResponse, null, 4)}</pre> */}
            <div className="mt-3 w-full">
              <div
                className="z-10 text-white rounded-md flex mx-2 sm:mx-5 md:mx-10 bg-white"
                style={{
                  backgroundColor: "#366092",
                }}
              >
                <p
                  onClick={() =>
                    // setSearchParams({
                    //   plan: "PREMIUM",
                    // })
                    navigate(
                      {
                        pathname: "/subscribePlan",
                        search: "?plan=PREMIUM",
                      },
                      {
                        replace: true,
                      }
                    )
                  }
                  style={{
                    borderBottomWidth:
                      searchParams.get("plan") === "PREMIUM" ? 2 : 0,
                  }}
                  className="font-bold w-1/2 p-2 flex justify-center items-center"
                >
                  Premium
                </p>
                <p
                  onClick={() =>
                    // setSearchParams({
                    //   plan: "VIP",
                    // })
                    navigate(
                      {
                        pathname: "/subscribePlan",
                        search: "?plan=VIP",
                      },
                      {
                        replace: true,
                      }
                    )
                  }
                  style={{
                    borderBottomWidth:
                      searchParams.get("plan") === "VIP" ? 2 : 0,
                  }}
                  className="font-bold w-1/2 p-2 flex justify-center items-center"
                >
                  Prime
                </p>
              </div>
              <div className="sm:mx-5 md:mx-10 mb-28">
                {searchParams.get("plan") === "PREMIUM"
                  ? points?.premium?.map((each) => (
                      <div className="flex mt-4">
                        <img className="h-10 w-10" src={each?.img} alt="" />
                        <div className="ml-2 flex flex-col">
                          <p className="font-bold">{each?.heading}</p>
                          <p className="text-xs opacity-75 max-w-[70vw]">
                            {each?.content}
                          </p>
                        </div>
                      </div>
                    ))
                  : points?.vip?.map((each) => (
                      <div className="flex mt-4">
                        <img className="h-10 w-10" src={each?.img} alt="" />
                        <div className="ml-2 flex flex-col">
                          <p className="font-bold">{each?.heading}</p>
                          <p className="text-xs opacity-75 max-w-[70vw]">
                            {each?.content}
                          </p>
                        </div>
                      </div>
                    ))}
                {/* {searchParams.get("plan") === "VIP" && (
                  <p className="text-center mt-4 text-red-300">
                    🔥 Launching This December
                  </p>
                )} */}
              </div>
            </div>
            {searchParams.get("plan") === "PREMIUM" ? (
              <button
                onClick={() => {
                  eventLog("PREMIUM_PAY_CLICK");
                  if (!subscriptions?.find((e) => e.planId === 663557))
                    initiatePayment(663557);
                }}
                style={{
                  opacity: subscriptions?.find((e) => e.planId === 663557)
                    ? 0.8
                    : 1,
                }}
                disabled={subscriptions?.find((e) => e.planId === 663557)}
                className={`font-bold fixed bottom-0 mb-5 bg-yellow-400 text-black w-[80vw] py-2 rounded-md mt-4 ${
                  subscriptions?.find((e) => e.planId === 663557)
                    ? "bg-blue-300 text-white"
                    : ""
                }`}
              >
                {loading ? (
                  "Loading"
                ) : subscriptions?.find((e) => e.planId === 663557) ? (
                  "Subscribed"
                ) : (
                  <span>
                    Go Premium with{" "}
                    <span className="relative">
                      <span className="opacity-75 absolute inset-0 flex justify-center items-center">
                        <span className="w-[90%] rotate-12 h-[3px] bg-red-500"></span>
                      </span>
                      ₹199
                    </span>{" "}
                    ₹99/month
                  </span>
                )}
              </button>
            ) : null}

            {searchParams.get("plan") === "VIP" ? (
              <button
                onClick={() => {
                  eventLog("VIP_PAY_CLICK");
                  if (!subscriptions?.find((e) => e.planId === 441843))
                    initiatePayment(441843);
                }}
                style={{
                  opacity: subscriptions?.find((e) => e.planId === 441843)
                    ? 0.8
                    : 1,
                }}
                disabled={subscriptions?.find((e) => e.planId === 441843)}
                className={`font-bold fixed bottom-0 mb-5 bg-yellow-400 text-black w-[80vw] py-2 rounded-md mt-4 ${
                  subscriptions?.find((e) => e.planId === 441843)
                    ? "bg-blue-300 text-white"
                    : ""
                }`}
              >
                {loading ? (
                  "Loading"
                ) : subscriptions?.find((e) => e.planId === 441843) ? (
                  "Subscribed"
                ) : (
                  <span>
                    Go Prime with{" "}
                    <span className="relative">
                      <span className="opacity-75 absolute inset-0 flex justify-center items-center">
                        <span className="w-[90%] rotate-12 h-[3px] bg-red-500"></span>
                      </span>
                      ₹999
                    </span>{" "}
                    ₹499/month
                  </span>
                )}
              </button>
            ) : null}

            {/* <button
              onClick={() => {
                initiatePayment(
                  searchParams.get("plan") === "PREMIUM" ? 663557 : 441843
                );
              }}
              className="fixed bottom-0 mb-5 bg-yellow-400 text-black w-[80vw] py-2 rounded-md mt-4"
            >
              {loading
                ? "Loading"
                : searchParams.get("plan") === "PREMIUM"
                ? "Go Premium with ₹99/month"
                : "Pre - Book VIP with ₹999/month"}
            </button> */}
          </div>
        </FadeIn>
      ) : null}
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 h-screen w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
    </>
  );
}

import React from "react";

import A from "../utils/API";

import ticket from "../assets/image/tickets.png";

import axios from "axios";
import useUser from "../hooks/useUser";
import useUtils from "../hooks/useUtils";
import { IoMdClose } from "react-icons/io";

export default function InviteFriend({ onClose }) {
  const { user, headers } = useUser();
  const [show, setShow] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const { openUrl } = useUtils();

  // const inviteUser = async () => {
  //   try {
  //     const { data } = await axios.post(A.INVITE_USER, {
  //       userId: user?.userId,
  //       usertoken: user?.accessToken,
  //     });
  //     alert(JSON.stringify(data, null, 4));
  //     console.log(data);
  //   } catch (er) {}
  // };

  const inviteUser = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(A.HOST + A.GET_SHARE_LINK, {}, headers);
      console.log(data);
      if (data?.inviteLink) {
        let text = `*${user.userName}* sent you a Rs.10,000 worth Pepul Golden Ticket. Click the link and create an account to participate in Quiz and get a chance to win upto Rs.10,000. ${data?.inviteLink}`;
        openUrl(`whatsapp://send?text=${text}`);
      }
    } catch (err) {}
    setLoading(false);
  };

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      className="z-30 fixed inset-0 inset-0 transition duration-500"
      style={{
        opacity: show ? 1 : 0,
        transform: show ? "translateY(0px)" : "translateY(10px)",
      }}
    >
      <div
        onClick={onClose}
        className="-z-10 absolute inset-0 bg-black opacity-50"
      ></div>
      <div className="absolute bottom-0 z-40 flex flex-col mt-5 w-full rounded-t-lg flex bg-yellow-300 p-4 md:p-10">
        <div onClick={onClose} className="flex justify-between">
          <div></div>
          <IoMdClose className="text-black text-lg" />
        </div>
        <p
          className="text-center text-blue-500 font-bold text-sm md:text-lg"
          style={{}}
        >
          Invite your friends to Pepul app and get 1 Golden Ticket free for 👑
          Prime Quiz
        </p>
        <div className="mt-2 flex justify-center">
          {/* <p className="max-w-[80%] text-center text-black opacity-75">
            You and your friend will get Golden Ticket and can win upto Rs.
            10,000
          </p> */}
        </div>
        <button
          onClick={() => (loading === false ? inviteUser() : null)}
          className="flex justify-center items-center py-3 md:py-4 rounded-md mt-4 bg-blue-400"
        >
          {loading ? (
            <>
              <p>Loading...</p>
            </>
          ) : (
            <>
              <img className="-rotate-12 w-[10%] z-10" src={ticket} alt="" />
              <p className="font-bold text-md ml-2 text-yellow-300">
                Invite your friends
              </p>
            </>
          )}
        </button>
      </div>
    </div>
  );
}

import React from "react";

export default function FadeIn({ children }) {
  const [opacity, setOpacity] = React.useState(0);

  React.useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <div
      className="transition duration-300"
      style={{
        opacity: opacity,
      }}
    >
      {children}
    </div>
  );
}

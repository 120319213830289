import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";

import { GoVerified } from "react-icons/go";

import tick from "../assets/image/tick.svg";
import useUtils from "../hooks/useUtils";

export default function VerifyProfile({
  showClose = false,
  onClose = () => {},
  openVerify = () => {},
}) {
  const [show, setShow] = React.useState(false);
  const { eventLog } = useUtils();

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      className="h-screen w-screen fixed flex flex-col justify-end z-50 transition duration-500"
      style={{
        opacity: show ? 1 : 0,
      }}
    >
      <div
        onClick={onClose}
        className="h-screen w-screen absolute bg-black bg-opacity-50"
      ></div>
      <div className="z-30 rounded-t-lg flex flex-col bg-[#252B37] p-5">
        {showClose ? (
          <div className="flex flex-col items-end">
            <AiFillCloseCircle onClick={onClose} className="text-2xl" />
          </div>
        ) : null}
        <div className="flex justify-center items-center">
          <img className="h-24" src={tick} alt="" />
        </div>
        <div className="flex justify-center">
          <p className="text-md max-w-[80vw] mb-3 text-center">
            Verify your account to withdraw money from Pepul. Verification is
            required to keep the Pepul more secure and spam free. So, Kindly
            Prepare your Adhaar Number for Verification
          </p>
        </div>
        <button
          onClick={() => {
            openVerify();
            setTimeout(() => {
              onClose();
            }, 3000);
            eventLog("PROFILE_VERIFY_CLICK");
          }}
          className="font-bold py-3 rounded-md mx-3 bg-[#028BF7] px-3 text-center"
        >
          Get Verified
        </button>
      </div>
    </div>
  );
}

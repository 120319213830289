import React from "react";
import axios from "axios";

import tick from "../../assets/image/tick.svg";
import useUser from "../../hooks/useUser";

import { PatternFormat } from "react-number-format";

import { AiFillCloseCircle } from "react-icons/ai";

import A from "../../utils/API";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Verify() {
  const { user, headers } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const [image, setImage] = React.useState(false);
  const [data, setData] = React.useState({
    name: "",
    number: null,
  });

  const [otp, setOTP] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState(false);

  const [response, setResponse] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const sendOTP = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const { data: res } = await axios.post(
        A.HOST + A.INITIATE_KYC,
        {
          number: data?.number,
        },
        headers
      );
      setResponse(res);
    } catch (err) {
      const errMessage = err?.response?.data?.message;
      if (errMessage) setErrorMessage(errMessage);
    }
    setLoading(false);
  };

  const verifyOTP = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const { data: res } = await axios.post(
        A.HOST + A.VALIDATE_KYC,
        {
          number: data?.number,
          otp: otp,
          clientId: response?.client_id,
          name: data?.name,
        },
        {
          ...headers,
          timeout: 100000000,
        }
      );
      // navigate(searchParams.get("redirect"), {
      //   replace: true,
      // });
      navigate(-1);
    } catch (err) {
      const errMessage = err?.response?.data?.message;
      if (errMessage) setErrorMessage(errMessage);
      if (errMessage === "undefined")
        setErrorMessage("Please Try Again Later...");
    }
    setLoading(false);
  };

  return (
    <div className="py-5 bg-blue-600 h-screen flex justify-between flex-col items-center overflow-y-scroll">
      <AiFillCloseCircle
        onClick={() => {
          navigate(-1);
        }}
        className="absolute top-0 left-0 text-4xl m-4"
      />
      <h1 className="text-center font-bold text-4xl text-yellow-400 mt-5">
        PEPUL
      </h1>
      <div className="flex flex-col justify-center items-center">
        <div className="relative">
          <img
            className="mt-3 border-yellow-400 w-[30vw] h-[30vw] rounded-full object-cover"
            src={user?.userImage}
            alt=""
          />
          <img className="absolute top-0 right-[10px] h-14" src={tick} alt="" />
        </div>
        <h1 className="mt-3 font-bold text-xl mb-2 text-[#4693E7]">
          Blue Tick Verification
        </h1>
        <p>Get Your Verified Badge Now</p>
        <p className="max-w-[80vw] text-center mt-10 text-xs opacity-50">
          We are doing Aadhaar verification to verify the respective user
          profile is created by a real authentic person or not. Together we can
          create a Safe & Positive social media platform.
        </p>
        {response ? (
          <>
            <input
              autoFocus
              value={otp}
              onChange={(e) =>
                e.target.value?.length <= 6 ? setOTP(e.target.value) : null
              }
              placeholder="######"
              className="font-bold tracking-widest text-3xl text-center border-blue-100 focus:outline-none px-2 py-2 mt-4 bg-blue-600 border-b-2 w-[80vw]"
              type="number"
              name=""
              id=""
            />
            <p className="mt-2 opacity-75 text-xs">OTP Sent</p>
            <p
              onClick={() => {
                setResponse(null);
                setTimeout(() => {
                  setOTP(null);
                }, 300);
              }}
              className="mt-2 opacity-75 text-xs underline"
            >
              Change Name or Number
            </p>
            {errorMessage ? (
              <p className="text-xs text-red-400 mt-3">{errorMessage}</p>
            ) : null}
          </>
        ) : (
          <>
            <input
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder="Name as per Aadhar"
              className="border-blue-100 focus:outline-none px-2 py-2 mt-4 bg-blue-600 border-b-2 w-[80vw]"
              type="text"
              name=""
              id=""
            />
            <input
              placeholder="Enter Aadhar Number"
              className="border-blue-100 focus:outline-none px-2 py-2 mt-2 bg-blue-600 border-b-2 w-[80vw]"
              type="number"
              name=""
              id=""
              value={data.number}
              onChange={(e) => setData({ ...data, number: e.target.value })}
            />
            {errorMessage ? (
              <p className="text-xs text-red-400 mt-3">{errorMessage}</p>
            ) : null}
            <p className="text-xs opacity-50 mt-3">
              Please Enter the 12 digit aadhar number
            </p>
          </>
        )}
      </div>
      {response ? (
        <button
          onClick={() => {
            if (!loading) verifyOTP();
            // alert("YEAH !!!");
          }}
          disabled={otp?.length !== 6 || loading === true}
          className="font-bold text-center bg-[#4693E7] w-[80vw] mt-4 py-3 rounded-full"
          style={{
            opacity: otp?.length === 6 && loading === false ? 1 : 0.5,
          }}
        >
          {loading ? "Loading" : "Verify"}
        </button>
      ) : (
        <button
          onClick={() => {
            if (!loading) sendOTP();
            // alert("YEAH !!!");
          }}
          disabled={
            !(data?.name !== "" && data?.number?.length > 11) || loading
          }
          className="font-bold text-center bg-[#4693E7] w-[80vw] mt-4 py-3 rounded-full"
          style={{
            opacity:
              data?.name !== "" &&
              data?.number?.length > 11 &&
              loading === false
                ? 1
                : 0.5,
          }}
        >
          {loading ? "Loading" : "Continue"}
        </button>
      )}
    </div>
  );
}

const MODE = "LIVE"; // LOCAL, STAGE, LIVE, TEST, UAT, SECURITY

const API = {};
if (MODE === "LOCAL") API["HOST"] = "http://localhost:3001";
if (MODE === "STAGE") API["HOST"] = "https://creatorsnode.hifrds.com";

// if (MODE === "LIVE") API["HOST"] = "http://192.168.29.162:3001";
if (MODE === "TEST") API["HOST"] = "https://creatortest.hifrds.com";
if (MODE === "LIVE") API["HOST"] = "https://pc.nxtgensn.in";
if (MODE === "DEMO") API["HOST"] = "https://creatordemo.hifrds.com";
if (MODE === "UAT") API["HOST"] = "https://uat-creators.hifrds.com";
if (MODE === "SECURITY") API["HOST"] = "https://seciospc.hifrds.com";

API.MODE = MODE;

// API.GET_QUIZ = "/api/v43/creatorService/quiz/getQuiz";
API.VALIDATE_USER = "/api/v43/creatorService/quiz/validateUserForQuiz";
API.HOME_DATA = "/api/v43/creatorService/quiz/getHomeData";
API.START_QUIZ = "/api/v43/creatorService/quiz/startQuiz";
API.ANSWER_QUIZ = "/api/v43/creatorService/quiz/answerQuiz";
API.UPDATE_UPI = "/api/v43/creatorService/quiz/updateUPI";
API.QUIZ_TIMEOUT = "/api/v43/creatorService/quiz/timeUpQuiz";
API.QUIZ_LOG = "/api/v43/creatorService/quiz/quizLog";
API.LEADERBOARD = "/api/v43/creatorService/quiz/getLeaderBoard";
API.RECENT_PARTICIPATENTS = "/api/v43/creatorService/quiz/recentParticipations";
API.HOME_DATA_MULTI = "/api/v43/creatorService/quiz/getHomeDataMulti";
API.START_QUIZ_MULTI = "/api/v43/creatorService/quiz/startQuizMulti";
API.ANSWER_QUIZ_MULTI = "/api/v43/creatorService/quiz/answerQuizMulti";
API.ANSWER_RESULT_MULTI = "/api/v43/creatorService/quiz/getResultMultiWithUpi";
API.SUBSCRIBE_CREATOR =
  "/api/v43/creatorService/subscriptions/subscribeCreator";
API.GET_LEADERBOARD_DATES = "/api/v43/creatorService/quiz/getLeaderBoardDates";
API.GET_LEADERBOARD_DATE_LIST =
  "/api/v43/creatorService/quiz/getLeaderBoardData";
API.UPDATE_DEFAULT_UPI = "/api/v43/creatorService/quiz/updateDefaultUPI";

// SUBSCRIPTIONS
API.GET_HOME_SUBSCRIPTIONS =
  "/api/v43/creatorService/quiz/getHomeDataWithPlans";
API.INITIATE_PAYMENT = "/api/v43/creatorService/quiz/initiatePayment";
API.PAYMENT_SUCCESS = "/api/v43/creatorService/quiz/paymentSuccess";
API.GET_QUIZ_ID = "/api/v43/creatorService/quiz/getQuizIdFromPlanId";
API.GET_ALREADY_SUBSCRIBED = "/api/v43/creatorService/quiz/getMySubscriptions";
API.MY_PLAN_HISTORY = "/api/v43/creatorService/quiz/myPlanHistory";
API.GET_STREAKS = "/api/v43/creatorService/quiz/getStreaks";

// GOLDEN TICKET
API.GET_GOLDEN_TICKET_COUNT =
  "/api/v43/creatorService/quiz/getGoldenTicketsCount";
API.GET_GOLDEN_TICKET_LIST =
  "/api/v43/creatorService/quiz/getGoldenTicketHistory";
API.GET_SHARE_LINK = "/api/v43/creatorService/quiz/getShareUrl";

// PAYOUT
API.GET_UNCLAIMED_QUIZ = "/api/v43/creatorService/quiz/getUnclaimedQuiz";
API.GET_TOTAL_UNCLAIMED_QUIZ =
  "/api/v43/creatorService/quiz/getTotalUnclaimedQuiz";
API.GET_PAYOUT_LINK = "/api/v43/creatorService/quiz/getPayoutLink";
API.CHECK_PAYMENT_STATUS = "/api/v43/creatorService/quiz/checkWithdrawStatus";

API.PUT_PAYOUT = "/api/v43/creatorService/quiz/getPayout";

// INVITE
API.INVITE_BANNER_USERS = "/api/v43/creatorService/quiz/getInvitedUsers";
API.INVITE_REFER_PAYOUT = "/api/v43/creatorService/quiz/initiateReferWithDraw";
API.GET_INVITE_STATUS = "/api/v43/creatorService/quiz/getInviteStatus";

API.GET_UPI = "/api/v43/creatorService/quiz/getSavedUPI";
API.VIEW_QUIZ_VIDEO = "/api/v43/creatorService/quiz/viewVideo";

// VERIFICATION
API.INITIATE_KYC = "/api/v43/creatorService/quiz/initiateKYC";
API.VALIDATE_KYC = "/api/v43/creatorService/quiz/validateKYC";

API.INVITE_USER =
  "https://stage.hifrds.com/dev/API/release_v35/invitedeeplink.php";

if (MODE === "LIVE")
  API.INVITE_USER =
    "https://stage.hifrds.com/dev/API/release_v35/inviteadd.php";

// if (MODE === "LIVE")
//   API["PHP_UPDATE"] =
//     "https://nxtgensn.in/dev/API/75934107_release_v37/participate_log.php";

export default API;

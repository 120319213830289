import React from "react";

import { GrClose } from "react-icons/gr";

import { IoClose } from "react-icons/io";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import FadeIn from "../../components/FadeIn";

export default function QuizComplete() {
  const navigate = useNavigate();
  return (
    <FadeIn>
      <AiOutlineCloseCircle
        onClick={() => {
          navigate("/home?page=QUIZ", {
            replace: true,
          });
        }}
        className="fixed top-0 right-0 m-5 text-4xl text-white"
      />
      <div className="h-screen flex flex-col justify-center items-center w-full bg-blue-500">
        <img className="h-28" src="/img/quiz.png" alt="" />
        <p className="my-10 text-center max-w-[80vw]">
          It looks like you've already participated in the quiz.We hope you had
          fun! But don't worry, we've got another one coming up tomorrow.
        </p>
        <button
          onClick={() => {
            navigate("/home?page=QUIZ");
          }}
          className="mt-5 font-bold text-black text-lg bg-yellow-400 w-[80vw] py-3 rounded-md"
        >
          Go Home
        </button>
      </div>
    </FadeIn>
  );
}

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import { BiCalendarEvent, BiRupee } from "react-icons/bi";
import { format } from "date-fns";

export default function Share() {
  const { user } = useUser();
  const navigate = useNavigate();
  const { shareScreenshot, saveScreenshot, formatMoney, addSuffix, getOS } =
    useUtils();
  const location = useLocation();

  const data = location.state;

  if (!data) navigate(-1);

  React.useEffect(() => {
    setTimeout(() => {
      if (location.state.type === "SHARE")
        shareScreenshot(
          `I won Rs.${formatMoney(
            data?.amount
          )} in Pepul Quiz today! I am sharing this here because you can win prizes, too, just by answering simple questions in the Pepul Quiz. You can participate for free any time of the day and enjoy the cash rewards! Download Pepul : https://pepul.app/j7auLCdLPuTFPqsr9`
        );
      if (location.state.type === "SAVE") saveScreenshot();
    }, 500);
  }, [location.state]);

  React.useEffect(() => {
    setTimeout(
      () => {
        navigate("/home?page=RESULTS&show=HISTORY", {
          replace: true,
        });
      },
      location.state.type === "SHARE" ? 10000 : 2000
    );
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col justify-center z-10">
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
      <div className="flex flex-col items-center">
        <img className="mt-3 h-32" src="/img/quiz.png" alt="" />
        <img className="mt-5 h-14" src="/img/winner.png" alt="" />
        <div className="my-4 mb-0 h-52 relative w-full">
          <div className="absolute inset-0 flex justify-center items-center">
            <img
              className="h-48 w-48 border-4 bg-black border-yellow-500 rounded-full object-cover"
              src={user?.userImage}
              alt=""
            />
          </div>
          <div className="absolute inset-0 flex justify-center items-end">
            <img className="mt-5 h-16" src="/img/greeting.png" alt="" />
          </div>
          <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center mb-3">
            <BiRupee className="text-xl text-yellow-800" />
            <p className="text-xl text-yellow-800 font-bold">
              {formatMoney(data?.amount)}
            </p>
          </div>
        </div>
        {data?.answerOrder < 4 && (
          <p className="mt-2 text-sm text-yellow-400 font-bold">
            {addSuffix(data?.answerOrder)} Winner
          </p>
        )}
        {data?.dateTime && (
          <p className="flex items-center opacity-75 font-bold">
            <BiCalendarEvent />
            <p className="ml-1">{format(new Date(data?.dateTime), "PP")}</p>
          </p>
        )}
        <div
          className="mx-5 w-full relative"
          style={{
            height: 100,
          }}
        >
          <p className="text-lg font-bold absolute inset-0 flex justify-center items-center z-10">
            {user?.userName}
          </p>
          <div className="absolute w-full h-full flex justify-center items-center">
            <img
              className="max-w-[400px]"
              style={{
                width: "75%",
              }}
              src="/img/bg-banner.png"
              alt=""
            />
          </div>
        </div>
        <img className="mt-2 h-10" src="/img/pepul-logo.svg" alt="" />
      </div>
    </div>
  );
}

import React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useLang from "../../hooks/useLang";

import VerifyProfile from "../../components/VerifyProfile";

import { BiEdit, BiRupee, BiShapeSquare } from "react-icons/bi";

import useUtils from "../../hooks/useUtils";
import A from "../../utils/API";
import Confetti from "react-confetti";
import FadeIn from "../../components/FadeIn";

export default function Upi() {
  const { user, headers } = useUser();
  const navigate = useNavigate();
  const { lang } = useLang();

  const { closeWindow, eventLog, playClick, protect } = useUtils();
  // const { id } = useParams();

  const [errorText, setErrorText] = React.useState("");

  const [showVerifyProfile, setShowVerifyProfile] = React.useState(
    user?.verifiedStatus === true ? false : true
  );

  const [upiFilled, setUPIFilled] = React.useState(false);

  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [checkbox, setCheckBox] = React.useState(true);
  const [saveUpi, setSaveUpi] = React.useState(false);

  const [complete, setComplete] = React.useState(false);

  const addUPI = async () => {
    if (!validUPI) return;
    setErrorText("");
    setLoading(true);
    try {
      const { data } = await axios.post(
        A.HOST + A.PUT_PAYOUT,
        {
          // quizId: id,
          upi: text,
          saveUpi: saveUpi,
        },
        headers
      );
      setComplete(data);
    } catch (err) {
      if (err?.response?.data?.message === "INVALID_UPI") {
        setLoading(false);
        setErrorText("Invalid UPI, Try Different UPI");
      } else if (err?.response?.data?.message === "REACHED_UPI_LIMIT") {
        setLoading(false);
        setErrorText(
          "This UPI has been used for many users, Try different UPI"
        );
      } else {
        closeWindow();
        setLoading(false);
        // alert(JSON.stringify(err, null, 4));
      }
    }
    setLoading(false);
  };

  // React.useEffect(() => {
  //   if (complete)
  //     setTimeout(() => {
  //       closeWindow();
  //     }, 3000);
  // }, [complete]);

  const fetchUPIDetails = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_UPI,
        {
          // quizId: id,
        },
        headers
      );
      if (data?.upi) {
        setText(data?.upi);
        setUPIFilled(data);
      }
      setData(data);
    } catch (err) {}
  };

  React.useEffect(() => {
    eventLog("UPI_PAGE_OPENED");
    fetchUPIDetails();
  }, []);

  const validUPI = () => /^[\w.-]+@[\w.-]+$/.test(text);

  return data ? (
    <FadeIn>
      {data?.verifiedStatus === false ? (
        <VerifyProfile
          showClose={false}
          openVerify={() => {
            navigate("/verify?redirect=" + "/upi");
          }}
        />
      ) : null}
      {complete === false ? (
        <div className="flex flex-col items-left bg-blue-500 min-h-screen my-auto px-5 py-10">
          <div className=" mb-5 flex items-center">
            {/* <BiRupee className="text-2xl" /> */}
            <h2 className="text-xl font-bold">{lang?.PAYMENT_INFO}</h2>
          </div>
          <p className="max-w-[300px] text-gray-400 mb-5">
            {upiFilled
              ? "Payment will be credited to this account"
              : lang?.CREDIT_ACCOUNT}
          </p>
          {upiFilled ? (
            <div
              onClick={() => {
                setText("");
                setErrorText("");
                setUPIFilled(false);
              }}
              className="flex flex-col"
            >
              <div className="flex">
                <label className="">{"Saved UPI"}</label>
                <div className="flex items-center text-yellow-500">
                  <p className="ml-4">Edit</p>
                  <BiEdit className="ml-1" />
                </div>
              </div>
              <p>{protect(text)}</p>
            </div>
          ) : (
            <div className="flex flex-col">
              <label className="">{lang?.ENTER_UPI}</label>
              <input
                type="text"
                name=""
                autoCapitalize="none"
                value={text}
                onChange={(e) => setText(e.target.value?.trim())}
                id=""
                placeholder="UPI ID"
                className="bg-[#30305c] my-3 px-3 border w-[90vw] h-12 outline-none border-gray-400 rounded-md"
              />
              <div
                onClick={() => setCheckBox(!checkbox)}
                className="flex items-center"
              >
                <input
                  onChange={(e) => setCheckBox(!checkbox)}
                  checked={checkbox}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p className="ml-2">{lang?.VALID_UPI}</p>
              </div>
              <div
                onClick={() => setSaveUpi(!saveUpi)}
                className="mt-2 flex items-center"
              >
                <input
                  // onChange={(e) => setSaveUpi(!saveUpi)}
                  checked={saveUpi}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p className="ml-1">Save my UPI ID for future withdrawals</p>
              </div>
            </div>
          )}
          {errorText ? <p className="mt-2 text-red-400">{errorText}</p> : null}
          <button
            onClick={() => {
              if (validUPI() && checkbox && loading === false) {
                addUPI();
                eventLog("UPI_SUBMIT");
                playClick();
              }
            }}
            style={{
              opacity: validUPI() && checkbox && loading === false ? 1 : 0.5,
            }}
            disabled={!validUPI() && checkbox}
            className="mt-4 font-bold border-none bg-yellow-500 text-black text-lg w-[90vw] h-12 rounded-md"
          >
            {loading ? "Loading...." : "Withdraw"}
          </button>
        </div>
      ) : (
        <div className="p-5 flex flex-col items-center justify-between min-h-screen my-auto bg-blue-900">
          <Confetti
            gravity={0.1}
            opacity={0.5}
            recycle={true}
            // run={false}
            width={window?.innerWidth}
            height={window?.innerHeight}
          />
          {/* {showVerifyProfile ? (
            <VerifyProfile
              onClose={() => {
                setShowVerifyProfile(false);
              }}
            />
          ) : null} */}
          {/* <img
            src="/img/dialog-bg.jpeg"
            className="fixed w-screen object-cover inset-0 bg-blue-500"
            style={{
              zIndex: -20,
            }}
          /> */}
          {/* <div
            style={{
              zIndex: -1,
            }}
            className="fixed opacity-75 h-screen w-screen bg-blue-500"
          ></div> */}
          {/* <img className="h-32" src="/img/success.gif" alt="" /> */}
          <div></div>
          <div className="flex flex-col justify-center items-center">
            <img className="h-32 mb-2" src="/img/success.gif" alt="" />
            <h1 className="uppercase font-bold text-yellow-400 text-4xl">
              Pepul Quiz
            </h1>
            <p className="my-5 text-lg text-center max-w-[80%]">
              Your amount will be credited to you bank account within 3 to 4
              business days
            </p>
            <h1 className="flex items-center uppercase font-bold text-yellow-400 text-4xl">
              {/* {JSON.stringify(complete?.activeSubscriptions)} */}
              <BiRupee />
              <p>{complete?.amount}</p>
              {/* {JSON.stringify(complete?.activeSubscriptions)} */}
            </h1>
          </div>
          {/* <p className="max-w-[80vw] text-center text-sm">
            The Winner's list will be announced before 11:55 PM. Your prize
            money will be credited to your given UPI account in 2 to 3 days.
            Keep enjoying Pepul with your family and friends.
          </p> */}
          {/* <p className="max-w-[80vw] opacity-50 text-center mt-5 ">
            <span>
              Sharing the previous contest's results will increase the chance of
              getting 1st Prize
            </span>
          </p> */}
          {/* complete?.activeSubscriptions?.length === 2 */}
          {true ? (
            <div className="flex flex-col">
              <button
                onClick={() => {
                  navigate("/home?page=QUIZ", {
                    replace: true,
                  });
                }}
                className="mt-10 font-bold text-black text-md bg-yellow-500 w-[300px] py-3 rounded-md"
              >
                Go Home
              </button>
            </div>
          ) : (
            <div className="flex flex-col">
              <button
                onClick={() => {
                  navigate(
                    `/subscribePlan?plan=${
                      !complete?.activeSubscriptions?.find(
                        (each) => each?.quizSubscriptionPlan?.amount === 99
                      )
                        ? "PREMIUM"
                        : "VIP"
                    }`,
                    {
                      replace: true,
                    }
                  );
                }}
                className="mt-10 font-bold text-black text-md bg-yellow-500 w-[300px] py-3 rounded-md"
              >
                Now, Win More
              </button>
              <button
                onClick={() => {
                  navigate("/home?page=QUIZ", {
                    replace: true,
                  });
                }}
                className="underline font-bold text-white text-md w-[300px] py-3 rounded-md"
              >
                Not Now
              </button>
            </div>
          )}
        </div>
      )}
    </FadeIn>
  ) : (
    <div className="min-h-screen bg-blue-500"></div>
  );
}

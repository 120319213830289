import React from "react";

import { GrClose } from "react-icons/gr";

import { IoClose } from "react-icons/io";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import FadeIn from "../../components/FadeIn";
import { format } from "date-fns";

export default function QuizStartTime() {
  const navigate = useNavigate();
  const { time } = useParams();
  return (
    <FadeIn>
      <AiOutlineCloseCircle
        onClick={() => {
          navigate("/home?page=QUIZ", {
            replace: true,
          });
        }}
        className="fixed top-0 right-0 m-5 text-4xl text-white"
      />
      <div className="h-screen flex flex-col justify-center items-center w-full bg-blue-500">
        <h1 className="font-bold text-4xl text-yellow-400">PEPUL QUIZ</h1>
        {/* <img className="h-28" src="/img/quiz.png" alt="" /> */}
        <h1 className="text-md font-bold mt-5">It's almost time!</h1>
        <p className="text-center max-w-[80vw]">
          You can participate in the quiz starting at{" "}
          {time ? format(new Date(time), "p") : null} <br />
          Stay tuned! Stay Excited
        </p>
      </div>
    </FadeIn>
  );
}

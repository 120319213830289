import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import {
  BsFillSaveFill,
  BsFillShareFill,
  BsFillTrophyFill,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import Countdown, { zeroPad } from "react-countdown";

import useLang from "../../hooks/useLang";
import useUser from "../../hooks/useUser";

import Confetti from "react-confetti";
import useUtils from "../../hooks/useUtils";

export default function Results() {
  const location = useLocation();
  const {
    closeWindow,
    eventLog,
    addSuffix,
    saveScreenshot,
    shareScreenshot,
    playSuccess,
    playClick,
  } = useUtils();
  const { user } = useUser();
  const navigate = useNavigate();
  const [data, setData] = React.useState(location.state);
  if (!data) navigate("/");

  const [screenshot, setScreenshot] = React.useState(false);

  const resultRef = React.useRef(null);

  const [opacity, setOpacity] = React.useState(1);

  const { lang } = useLang();

  React.useEffect(() => {
    if (data?.result === true) eventLog("RIGHT_ANSWER", data?.quizId);
    if (data?.result === false) eventLog("WRONG_ANSWER", data?.quizId);
  }, []);

  // const takeScreenshot = () =>
  //   html2canvas(document.body).then(function (canvas) {
  //     document.body.appendChild(canvas);
  //   });

  React.useEffect(() => {
    document.body.scrollTop = 0;
    if (screenshot) {
      if (screenshot === "SAVE") saveScreenshot();
      if (screenshot === "SHARE")
        shareScreenshot(
          "Hey! I just got real money ! Download Pepul app https://apps.apple.com/in/app/pepul-social-network-app/id1571517301"
        );

      setTimeout(() => {
        setScreenshot(false);
      }, 3000);
    }
  }, [screenshot]);

  React.useEffect(() => {
    if (data && data?.result) playSuccess();
  }, [data]);

  return (
    <>
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
      {data?.result ? (
        <div
          ref={resultRef}
          className="flex flex-col justify-center items-center max-w-[1000px] min-h-screen pb-10"
        >
          <Confetti
            gravity={0.3}
            opacity={opacity}
            recycle={false}
            // run={false}
            width={window?.innerWidth}
            height={window?.innerHeight}
          />
          <div className="text-center">
            <img className="h-40" src="/img/quiz.png" alt="" />
            {/* {JSON.stringify(data, null, 4)} */}
            <p className="mt-2 text-2xl font-bold">{lang.CONGRATULATIONS} 🎉</p>
            {/* {data?.position ? (
              <p className="font-bold pb-4 text-yellow-500 flex justify-center items-center">
                <BsFillTrophyFill />
                <p className="ml-2">
                  {addSuffix(data?.position)} {lang.WINNER}
                </p>
              </p>
            ) : null} */}
          </div>
          <div className="flex flex-col justify-center items-center">
            <img
              src={user?.userImage}
              alt=""
              className="h-20 w-20 rounded-full object-cover border-4 border-white mb-4"
            />
            <h2 className="font-bold text-2xl text-yellow-500 mb-4">
              {user?.userName}
            </h2>
            <p className="text-2xl mb-4 font-bold">{lang?.WON}</p>
            <p className="max-w-[80vw] text-sm text-center">
              {lang?.THANK_YOU_PARTICIPATION}
            </p>
            <p className="max-w-[80vw] text-sm text-center">
              {lang?.RESULT_PUBLISH_AFTER_9}
            </p>
            <p className="mt-2 text-sm text-center">{lang?.CLICK_BUTTON}</p>
            <button
              onClick={() => {
                navigate("/upi/" + data?.quizId);
                eventLog("CLAIM_CLICK", data?.quizId);
                playClick();
              }}
              className="max-w-[80vw] bg-yellow-500 md:w-[500px] sm:w-[90vw] py-3 rounded-lg md:text-xl sm:text-g text-black font-bold my-5"
            >
              {lang?.CLAIM_REWARD}
            </button>
            {/* {JSON.stringify(user, null, 4)} */}
            {/* <p className="text-sm text-center">
            Share this achivement with your friends!
          </p> */}
            {/* <div className="flex justify-center items-center mt-5 w-10">
              <img src="./img/whatsapp.png" alt="" className="mr-5" />
              <img src="./img/instagram.png" alt="" className="mr-5" />
              <img src="./img/twitter.png" alt="" className="mr-5" />
            </div> */}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center max-w-[1000px] min-h-screen my-auto bg-red-500">
          <div className="text-center">
            <div className="w-90 h-30">
              <img className="h-40" src="/img/quiz.png" alt="" />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[500px] sm:w-[90vw] mb-8">
            <p className="text-lg mb-2">{lang?.BETTER_LUCK}</p>
            <h2 className="font-bold max-w-[80vw] text-2xl text-center mb-5">
              {lang?.CREDIT_1_RUPEE}
            </h2>

            <p className="text-lg mb-5">{lang?.REASON}</p>
            <img
              src="/img/one-rupee.png"
              alt=""
              width={"100px"}
              className="mb-4"
            />
            {data?.nextOpen ? (
              <>
                <p className="mt-2 text-sm">{lang?.NEXT_CONTEST}</p>
                <p className="md:text-[50px] sm:text-[40px] font-bold">
                  <Countdown
                    date={data?.nextOpen}
                    renderer={({ hours, minutes, seconds, completed }) => (
                      <p>
                        {zeroPad(hours)} : {zeroPad(minutes)} :{" "}
                        {zeroPad(seconds)}{" "}
                      </p>
                    )}
                  />
                </p>
              </>
            ) : null}
            <p className="text-md text-center">{lang?.CLICK_BUTTON}</p>
            <button
              onClick={() => {
                navigate("/upi/" + data?.quizId);
                eventLog("CLAIM_CLICK", data?.quizId);
                playClick();
              }}
              className="max-w-[80vw] bg-yellow-500 md:w-[500px] sm:w-[90vw] h-16 rounded-lg md:text-xl sm:text-g text-black font-bold mt-5"
            >
              {lang?.CLAIM_REWARD}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";

import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

// import useUtils from "../../hooks/useUtils";

export default function Rules() {
  const navigate = useNavigate();
  // const { getOS } = useUtils();
  React.useEffect(() => {
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      />
      <div className="min-h-screen w-screen overflow-y-screen">
        <div className="">
          <div className="py-5 flex items-center">
            <IoIosArrowBack
              //   onClick={() => {
              //     closeWindow();
              //   }}
              onClick={() => {
                navigate(-1);
              }}
              className="ml-2 text-4xl"
            />
            <h1 className="text-xl ml-2">Terms and Conditions</h1>
            {/* Rules & Contest Guidelines */}
          </div>
          <p className="text-justify px-4 text-md my-2 mx-2">
            Pepul (We/Us/Platform) is a creator-first positive social media
            platform that facilitates content creation on the platform. Pepul
            App's mission is to bring informative & skill-based, entertainment
            videos from creators to the world. Therefore, all the quiz contests
            conducted on this platform are for the users' benefit in terms of
            knowledge and personal development. All the participants can watch
            videos at their convenience, get a chance to win cash prizes, and
            develop themselves at the same time. By participating in the quiz
            contest, every participant will get a chance to win monetary
            benefits for their efforts in the contest. We launched a quiz
            contest in November as part of our user interaction initiative.
            <br />
            Following are the rules and disclaimers applicable to you (the
            participant) with respect to the quiz contest: <br /> a) Upon
            clicking on the advertisement or inside-app contest page to join our
            quiz, you can participate in our quiz contest. <br /> b) Upon
            entering the quiz, you will get the free quiz contest; if you
            participate in a free quiz contest, you get a chance to win up to Rs
            500 daily. <br />
            c) Upon viewing the video content while participating in the quiz,
            you will be redirected to a webpage wherein the quiz based on the
            video content will appear. Kindly note that you will be allowed to
            view the video content only once, and upon being redirected to the
            webpage consisting of the quiz, you will not be permitted to revisit
            the webpage containing the video content. <br />
            d) Upon being redirected to the webpage containing the quiz
            question, you will be required to answer the quiz question by way of
            choosing an answer. <br />
            e) You, as a participant, agree that you will use considerable
            skill, judgement, and discretion while answering the questions
            displayed on the webpage. <br />
            f) You will assess the options provided to you with respect to the
            questions displayed on the webpage, and your act of answering the
            quiz question through the webpage shall be deemed your final
            opportunity to answer the quiz question. <br />
            g) If you are declared a winner, you must complete the KYC (know
            your customer) procedure on the web page before receiving your
            winnings from the app. Upon successful verification of your details
            and UPI ID, you will be paid directly through the details you
            provided. <br />
            h) You acknowledge and agree that your participation in the Pepul
            Quiz Contest would amount to your participation in a game of skill.
            By no means would your participation in the Pepul Quiz Contest be
            deemed as a participation in a game of chance as the said Quiz
            Contest requires a User to exercise his/her skill to arrive at an
            answer. <br />
            i) You agree that you will abide by the rules presented herein and
            shall not violate the same in any manner whatsoever. <br />
            j) At any given point during your participation, you will be
            required to provide your UPI details to us. We herein represent and
            warrant that we will use the UPI ID for the sole purpose of awarding
            prizes to you and that the said ID shall not be used or shared in
            any manner whatsoever, apart from the manner permitted by law.
            <br />
            k) The selected winner and the range of payment as mentioned in
            Clause (c) shall be on a first-come, first-served basis. (On the
            basis of the correct answer and the fastest.) <br />
            l) As a participant, you agree that you are over and above the age
            of 18 (eighteen) years. <br />
            m) The Participant shall abide by all the rules and regulations of
            participating in the Quiz from time to time. <br />
            n) By entering the quiz, the user agrees to be bound by these terms
            and conditions, as mentioned above. <br />
            o) The user agrees that the cash prize amount for quiz winners may
            vary and that it's not a fixed amount at all times. <br />
            p) The app offers calling bonuses via friends of friends. a friend
            while calling his six friends. If the invitee has installed and
            signed up for the app, the inviter will get a reward of Rs. 21 and
            the invitee will get a reward of Rs. 3. KYC verification is required
            to get the reward. You can get rewards only after doing KYC. If a
            person commits malpractice or engages in undesirable activities in
            order to claim a reward, they are considered ineligible to make the
            claim. Any changes to the app will vary the terms you agreed to
            above. <br />
            q) We have saved the UPI ID you provide as encrypted for transaction
            purposes. <br />
            r) As a user, you agree that we are responsible for making any
            changes to this quiz.
          </p>
        </div>
      </div>
    </>
  );
}

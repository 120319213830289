import axios from "axios";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import A from "../../utils/API";

export default function CheckQuiz() {
  const { headers } = useUser();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const { closeWindow } = useUtils();

  const checkQuizId = async () => {
    try {
      const condition = {
        planId: id,
      };
      const ticketId = searchParams.get("ticketId");
      if (ticketId) condition["ticketId"] = ticketId;
      const { data } = await axios.post(
        A.HOST + A.GET_QUIZ_ID,
        condition,
        headers
      );
      if (data?.upiQuizId)
        navigate("/upi/" + data?.upiQuizId, {
          replace: true,
        });
      if (data?.quizId)
        navigate(
          "/videoWithQuiz/" +
            data?.quizId +
            (ticketId ? "?ticketId=" + ticketId : ""),
          {
            replace: true,
          }
        );
      if (data?.completeStatus)
        navigate("/quizComplete", {
          replace: true,
        });
      if (data?.startTime)
        navigate("/quizStartTime/" + data?.startTime, {
          replace: true,
        });
      // closeWindow();
    } catch (er) {
      closeWindow();
    }
  };

  React.useEffect(() => {
    checkQuizId();
  }, []);
  return (
    <div>
      {/* <img
        src="/img/bg.png"
        className="fixed object-cover inset-0 h-screen w-screen bg-blue-400"
        style={{
          zIndex: -10,
        }}
      /> */}
    </div>
  );
}

import React from "react";
import { AiFillCloseCircle, AiFillWarning } from "react-icons/ai";

import { GoVerified } from "react-icons/go";

import tick from "../assets/image/tick.svg";
import useUtils from "../hooks/useUtils";

export default function PremiumPrimeStop({
  showClose = false,
  onClose = () => {},
  openVerify = () => {},
}) {
  const [show, setShow] = React.useState(false);
  const { eventLog } = useUtils();

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      className="h-screen w-screen fixed flex flex-col justify-end z-50 transition duration-500"
      style={{
        opacity: show ? 1 : 0,
        transform: show ? "translateY(0)" : "translateY(20px)",
      }}
    >
      <div
        onClick={onClose}
        className="h-screen w-screen absolute bg-black bg-opacity-50"
      ></div>
      <div className="z-30 rounded-t-lg flex flex-col bg-[#252B37] p-5">
        {showClose ? (
          <div className="flex flex-col items-end">
            <AiFillCloseCircle onClick={onClose} className="text-2xl" />
          </div>
        ) : null}
        <div className="flex justify-center items-center">
          {/* <img className="h-24" src={tick} alt="" /> */}
          {/* <AiFillWarning className={"text-5xl text-blue-200 mb-3"} /> */}
        </div>
        <div className="flex justify-center">
          <p className="text-md max-w-[80vw] mb-3 text-center">
            Premium Quiz and Prime Quiz are closed. Sorry for the inconvenience
            caused. If you have active premium or prime subscriptions, your
            amount will be refunded and It will be reflected to your account
            within 3 to 4 working days. Thank you for your support ❤️ Please
            Enjoy Free Daily Quiz and win upto 1000 daily.
          </p>
        </div>
        <button
          onClick={() => {
            openVerify();
            setTimeout(() => {
              onClose();
            }, 3000);
            eventLog("PROFILE_VERIFY_CLICK");
          }}
          className="text-black font-bold py-3 rounded-md mx-3 bg-yellow-400 px-3 text-center"
        >
          OK
        </button>
      </div>
    </div>
  );
}

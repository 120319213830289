import React from "react";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import A from "../../utils/API";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FadeIn from "../../components/FadeIn";
import { format } from "date-fns";
import { BiRupee } from "react-icons/bi";
import { BsClock } from "react-icons/bs";

export default function MyPlanHistory() {
  const { user, headers } = useUser();
  const navigate = useNavigate();
  const { formatMoney } = useUtils();

  const [history, setHistory] = React.useState(false);

  const fetchHistory = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.MY_PLAN_HISTORY,
        {},
        headers
      );
      setHistory(data);
    } catch (err) {}
  };

  React.useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="h-screen pt-4 bg-blue-500">
      <div className="flex items-center">
        <IoIosArrowBack
          onClick={() => {
            navigate(-1);
          }}
          className="top-0 left-0 text-4xl"
        />
        <h1 className="ml-2 text-lg">My Plan History</h1>
      </div>
      <FadeIn>
        {history && history?.length === 0 ? (
          <p className="text-center my-4">No Payments Yet</p>
        ) : null}
        {history &&
          history?.map((each) => (
            <div
              style={{
                backgroundColor: "#58799E",
              }}
              className="flex items-center px-5 py-2 rounded-md m-3"
            >
              <div className="w-full flex flex-col">
                <p className="opacity-75 mt-2">
                  Paid on {format(new Date(each?.paidDate), "PP")}
                </p>
                <div className="flex w-full justify-between items-center">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl mt-2">Watch and Win upto</p>
                    <p className="flex items-center mt-1 text-2xl font-bold text-yellow-400">
                      <BiRupee />
                      {formatMoney(each?.amountUpto)}
                    </p>
                  </div>
                  <div className="text-center bg-yellow-500 text-black px-3 py-2 rounded-md">
                    Plan Subscribed
                  </div>
                </div>

                <p className="-mt-1 text-yellow-400">Everyday</p>
                {/* <p className="mt-2 flex items-center">
                  <BsClock />
                  <p className="mx-1">Subscription Ends on </p>
                  {format(new Date(each?.subscriptionEndsOn), "PP")}
                </p> */}
              </div>
            </div>
          ))}
      </FadeIn>
    </div>
  );
}

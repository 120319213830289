import React from "react";

import { useLocation } from "react-router-dom";

import Countdown, { zeroPad } from "react-countdown";

import useUtils from "../../hooks/useUtils";
import useLang from "../../hooks/useLang";

export default function TimeOut() {
  const location = useLocation();
  const { lang } = useLang();
  const { closeWindow, eventLog } = useUtils();
  React.useEffect(() => {
    setTimeout(() => {
      closeWindow();
    }, 5000);
    eventLog("TIMEOUT");
  }, []);
  return (
    <div className="flex flex-col items-center h-screen w-screen bg-red-500">
      <img className="mt-10 h-40" src="/img/quiz.png" alt="" />
      <p className="font-bold text-xl max-w-[80vw] text-center">
        {lang?.TIME_EXPIRED}
      </p>
      <img className="mt-10 h-60" src="/img/time.gif" alt="" />
      {location.state?.nextOpen ? (
        <>
          <p className="mt-2 text-sm">{lang?.NEXT_CONTEST}</p>
          <p className="md:text-[50px] sm:text-[40px] font-bold">
            <Countdown
              date={location.state?.nextOpen}
              renderer={({ hours, minutes, seconds, completed }) => (
                <p>
                  {zeroPad(hours)} : {zeroPad(minutes)} : {zeroPad(seconds)}{" "}
                </p>
              )}
            />
          </p>
        </>
      ) : null}
    </div>
  );
}

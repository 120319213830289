import axios from "axios";
import React from "react";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import FadeIn from "../../components/FadeIn";

import ticketBackJSON from "../../assets/lottie/loop.json";

import image99 from "../../assets/image/99_ticket.png";
import image999 from "../../assets/image/999_ticket.png";

import ticket from "../../assets/image/tickets.png";

import A from "../../utils/API";
import { format } from "date-fns";
import Lottie from "react-lottie";
import InviteFriend from "../../components/InviteFriend";

export default function Tickets() {
  const navigate = useNavigate();
  const [tickets, setTickets] = React.useState(false);
  const { headers, user } = useUser();
  const { formatMoney } = useUtils();
  const [showTickets, setShowTickets] = React.useState(true);

  const fetchTickets = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_GOLDEN_TICKET_LIST,
        {},
        headers
      );
      setTickets(data);
    } catch (err) {}
  };

  React.useEffect(() => {
    fetchTickets();
  }, []);

  return (
    <div className="h-screen w-screen bg-blue-500">
      {showTickets ? (
        <InviteFriend
          onClose={() => {
            setShowTickets(false);
          }}
        />
      ) : null}
      {/* NAV BAR */}
      <div className="flex items-center">
        <IoIosArrowBack
          onClick={() => {
            navigate("/home?page=QUIZ");
          }}
          className="m-2 mt-5 top-0 left-0 text-4xl"
        />
        <h1 className="mt-3 text-lg">Golden Tickets</h1>
      </div>
      {/* <div className="flex flex-col">{JSON.stringify(tickets, null, 4)}</div> */}
      {tickets && tickets?.tickets?.length === 0 ? (
        <FadeIn>
          <div className="overflow-hidden h-[90vh] flex flex-col justify-center items-center">
            <div className="relative flex justify-center items-center h-[50%] w-[100%]">
              <Lottie
                options={{
                  loop: true,
                  animationData: ticketBackJSON,
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  //   height: "70%",
                  //   marginTop: -200,
                  // position: "absolute",
                  transform: "scale(1.4)",
                }}
                width="full"
              />
              <img
                className="-rotate-12 w-[50%] z-10 top-0 left-0 right-0"
                src={ticket}
                alt=""
              />
            </div>
            <div className="flex justify-center">
              <p className="max-w-[80vw] mt-5 text-center">
                No Tickets Yet, Participate in any of the quiz and win free
                golden tickets
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <button
                onClick={() => {
                  navigate("/home?page=QUIZ", {
                    replace: true,
                  });
                }}
                className="font-bold bg-yellow-500 text-black py-2 rounded-md py-3 mt-10 w-[80vw]"
              >
                Participate
              </button>
              <p className="mt-4 text-yellow-400 max-w-[80vw] text-center text-xs">
                By using golden ticket you can participate for free in Premium
                or Prime quiz and win upto 10,000 daily
              </p>
            </div>
          </div>
        </FadeIn>
      ) : (
        <></>
      )}
      <table>
        {tickets && tickets?.tickets?.length > 0
          ? tickets?.tickets?.map((each, idx) => (
              <tr className={idx % 2 === 0 ? "bg-blue-300" : "bg-blue-400"}>
                <td>
                  <div className="p-3">
                    <img
                      className="-rotate-12 w-[20vw]"
                      src={
                        each?.quizTicket?.quizSubscriptionPlan?.amount === 99
                          ? image99
                          : each?.quizTicket?.quizSubscriptionPlan?.amount ===
                              999 ||
                            each?.quizTicket?.quizSubscriptionPlan?.amount ===
                              499
                          ? image999
                          : ""
                      }
                      alt=""
                    />
                  </div>
                </td>
                <td>
                  <div className="p-3 flex flex-col">
                    <p className="font-bold">
                      You won{" "}
                      {each?.quizTicket?.quizSubscriptionPlan?.amountUpto
                        ? formatMoney(
                            each?.quizTicket?.quizSubscriptionPlan?.amountUpto
                          )
                        : ""}{" "}
                      quiz Golden ticket{" "}
                    </p>
                    <p className="text-xs">
                      {each?.date ? format(new Date(each?.date), "PP") : null}
                    </p>
                  </div>
                </td>
                <td>
                  {each?.claimStatus === "CLAIMED" ? (
                    <div className="w-[30vw] text-xs p-4">
                      <p>Used on</p>
                      <p className="mt-1">
                        {each?.claimDateTime
                          ? format(new Date(each?.claimDateTime), "PP")
                          : null}
                      </p>
                    </div>
                  ) : new Date() >= new Date(each?.expireDate) ? (
                    <div className="w-[30vw] text-xs p-4">
                      <p>Expired on</p>
                      <p className="mt-1">
                        {each?.expireDate
                          ? format(new Date(each?.expireDate), "PP")
                          : null}
                      </p>
                    </div>
                  ) : (
                    <div className="w-[30vw] text-xs p-4">
                      <p>Expires on</p>
                      <p className="mt-1">
                        {each?.expireDate
                          ? format(new Date(each?.expireDate), "PP")
                          : null}
                      </p>
                    </div>
                  )}
                </td>
              </tr>
            ))
          : null}
      </table>
      {/* <div className="flex bg-blue-300 py-2 px-4 justify-center">
        <p className="text-xs bg-white px-3 py-2 rounded-full text-black">
          Golden Ticket for 99
        </p>
        <p className="text-xs ml-2 bg-white px-3 py-2 rounded-full text-black">
          Golden Ticket for 999
        </p>
      </div> */}
    </div>
  );
}

import React from "react";

import ticketBackJSON from "../assets/lottie/loop.json";

import image99 from "../assets/image/99_ticket.png";
import image999 from "../assets/image/999_ticket.png";
import tick from "../assets/image/tick.svg";

import Lottie from "react-lottie";
import FadeIn from "./FadeIn";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import useUtils from "../hooks/useUtils";

import userAdd from "../assets/image/usersAdd.gif";

import { AiFillCloseCircle } from "react-icons/ai";
import { FiRotateCw, FiUserPlus } from "react-icons/fi";
import { TbUserCheck } from "react-icons/tb";
import { BiRupee } from "react-icons/bi";
import useUser from "../hooks/useUser";
import axios from "axios";

import A from "../utils/API";
import VerifyProfile from "./VerifyProfile";

const InviteIcon = ({ inviteData = {} }) => {
  const { user, headers } = useUser();
  const [loading, setLoading] = React.useState(false);
  const { openUrl, eventLog } = useUtils();

  const inviteUser = async () => {
    eventLog("INVITE_PROFILE_ICON_CLICK");
    setLoading(true);
    try {
      const { data } = await axios.post(A.HOST + A.GET_SHARE_LINK, {}, headers);
      console.log(data);
      if (data?.inviteLink) {
        let text = `${user?.userName} sent *Rs.${inviteData.joinAmount}* 🎁 Gift for you

*How to Claim Your Gift*
✅ Download Pepul App using this link - ${data?.inviteLink}
✅ Sign up in Pepul 
✅ Verify Your Profile ✨
✅ Participate in Daily Quiz ✨
✅ You will get your Gift 🎁 Link in WhatsApp
        
🔥Use Pepul Daily for more exciting Rewards and Gifts 🎁`;
        openUrl(`whatsapp://send?text=${text}`);
      }
    } catch (err) {}
    setLoading(false);
  };

  return (
    <div
      onClick={() => (loading ? null : inviteUser())}
      className="bg-gradient-to-r from-[#4F5F7F] rounded-lg border-dashed h-[10vh] w-[10vh] border-2 flex justify-center items-center"
    >
      {loading ? (
        <FiRotateCw className="animate-spin text-[5vw]" />
      ) : (
        <img className="h-16 object-cover" src={userAdd} alt="" />
      )}
    </div>
  );
};

export default function InviteEarn({ onClose = () => {}, inviteData = {} }) {
  const [ready, setReady] = React.useState(false);
  const navigate = useNavigate();
  const { formatMoney, eventLog, openUrl } = useUtils();
  const { user, headers } = useUser();

  const [data, setData] = React.useState(false);
  const [showVerify, setShowVerify] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (data)
      setTimeout(() => {
        setReady(true);
      }, 0);
  }, [data]);

  const fetchInviteData = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.INVITE_BANNER_USERS,
        {},
        headers
      );
      setData(data);
    } catch (err) {}
  };

  React.useEffect(() => {
    fetchInviteData();
  }, []);

  const fetchLink = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        A.HOST + A.INVITE_REFER_PAYOUT,
        {},
        headers
      );
      if (data?.verified === false) {
        setShowVerify(true);
      }
      if (data?.payout?.paymentLink) {
        // alert(data?.payout?.paymentLink);
        openUrl(data?.payout?.paymentLink);
        setTimeout(() => {
          fetchInviteData();
        }, 3000);
      }
    } catch (err) {}
    setLoading(false);
  };

  // React.useEffect(() => {
  //   eventLog("TICKET_POPUP_SHOW");
  // }, []);

  return (
    <div
      className="transition duration-500 z-20 fixed h-screen w-screen flex justify-center items-center bg-black bg-opacity-75"
      style={{
        transform: ready ? "translateY(0)" : "translateY(10px)",
        opacity: ready ? 1 : 0,
      }}
    >
      <div className="relative overflow-y-scroll flex flex-col items-center justify-around shadow-xl w-[90vw] h-[95vh] bg-blue-600 rounded-md py-2">
        <AiFillCloseCircle
          onClick={() => {
            setShowVerify(false);
            onClose();
            eventLog("TICKET_POP_CLOSE");
          }}
          className="z-10 absolute top-0 right-0 text-3xl m-5"
        />
        {/* {data?.limitStatus === false && (
          <img className="mt-5 h-24" src="/img/quiz.png" alt="" />
        )} */}
        <h1 className="text-3xl font-bold text-yellow-400">PEPUL QUIZ</h1>
        {data?.limitStatus === false && (
          <div className="flex flex-col justify-center items-center">
            <h1 className="mb-3 font-bold">Congratulations 🎉</h1>
            <img
              className="min-h-20 h-20 border-2 border-white rounded-full min-w-20 w-20 object-cover"
              src={user?.userImage}
              alt=""
            />
            <h1 className="font-bold text-lg text-yellow-500 mt-2">
              {user?.userName}
            </h1>
            <p className="mt-2 flex items-center font-bold text-xl">
              You Won
              <img src="/img/bonus.gif" className="h-8 ml-1 text-xl mr-1" />
            </p>
            {/* <h1 className="font-bold text-md my-2"></h1> */}
            {/* <p className="mt-2 flex items-center font-bold text-xl">
              You Got Rs.{inviteData?.joinAmount * 6}
              <img src="/img/bonus.gif" className="h-8 ml-1 text-xl mr-1" />
            </p> */}
            {/* <p className="text-yellow-500 mt-2 flex items-center font-bold text-sm mb-2">
              Your Friends Got Rs.{inviteData?.referAmount}
            </p> */}
            <p className="mt-1 leading-5 text-white text-xs max-w-[90%] text-center">
              You got the opportunity to thank your favorite friends & family.
              Show them your love. Invite your loved ones.
            </p>
            <p className="mt-1 leading-5 text-yellow-500 text-xs max-w-[90%] text-center mb-2">
              Once they joined & verified their profiles, you will get Rs.
              {inviteData?.referAmount}
            </p>
            {/* <p className="mt-1 leading-5 text-yellow-400 text-xs max-w-[80%] text-center mb-2">
              You got Rs.{inviteData?.joinAmount * 6} for your friends. Gift to
              your friends & family. Once all joined, You can get Rs.
              {inviteData?.referAmount} and one Golden ticket.
            </p> */}
          </div>
        )}
        <p className="text-xs">
          {data?.slots?.filter((each) => each.type === "UNLOCKED")?.length} out
          of {data?.slots?.length} to be verified to get Rs.{" "}
          {inviteData?.referAmount}
        </p>
        {data?.limitStatus === false && (
          <div className="overflow-hidden relative w-[70%] h-2 rounded-md bg-[#4F5F7F]">
            <div
              className="rounded-full absolute h-full bg-green-500"
              style={{
                width:
                  (data?.slots?.filter((each) => each.type === "UNLOCKED")
                    ?.length /
                    data?.slots?.length) *
                    100 +
                  "%",
              }}
            ></div>
          </div>
        )}
        <div className="mt-5 w-[80vw] gap-y-4 flex flex-wrap gap-x-4 justify-center items-center">
          {data?.limitStatus === false &&
            data?.slots?.map((each) => (
              <div className="w-1/4 flex justify-center items-center flex-col">
                {each?.type === "UNLOCKED" ? (
                  <div className="relative bg-gradient-to-r from-[#4F5F7F] rounded-lg border-dashed h-[10vh] w-[10vh] border-2 flex justify-center items-center">
                    {/* <FiUserPlus className="text-[7vw]" /> */}
                    <img
                      className="h-full w-full object-cover"
                      src={each?.userImage}
                      alt=""
                    />
                    <p className="flex items-center justify-center absolute bottom-0 left-0 right-0 h-6 bg-black/[0.6] text-[11px] text-center">
                      Verified
                      {/* <MdVerified className="ml-1 text-green-500 rounded-full" /> */}
                      <img className="h-[18px]" src={tick} alt="" />
                    </p>
                  </div>
                ) : (
                  <InviteIcon inviteData={inviteData} />
                )}
                <p className="text-lg text-yellow-500 flex items-center mt-2 font-bold">
                  <BiRupee />
                  <p className="ml-1">{inviteData?.joinAmount}</p>
                </p>
              </div>
            ))}
          {data?.limitStatus && (
            <div className="border-0 p-5 px-8 rounded-md gap-y-2 mb-[10vh] flex flex-col justify-center items-center">
              <TbUserCheck className="text-6xl" />
              <p className="text-center font-bold text-lg text-yellow-500">
                Refer Limit Reached
              </p>
              <p className="text-center text-xs opacity-50">
                Thank you for using Pepul
              </p>
              <p className="text-center text-xs opacity-50">
                Keep using Pepul to get more rewards
              </p>
            </div>
          )}
        </div>
        {data?.unlockStatus === true && (
          <button
            disabled={data?.unlockStatus === true ? false : true}
            className="font-bold bg-yellow-400 text-black rounded-md mt-5 py-3 w-[80vw]"
            style={{
              opacity:
                data?.unlockStatus === true && loading === false ? 1 : 0.5,
            }}
            onClick={() => {
              if (loading === false && data?.unlockStatus === true) {
                fetchLink();
              }
              eventLog("WITHDRAW_CLICK");
            }}
          >
            {loading ? "Loading..." : "Withdraw Rs. " + inviteData?.referAmount}
          </button>
        )}
        {showVerify ? (
          <VerifyProfile
            showClose={false}
            openVerify={() => {
              navigate("/verify?redirect=" + "/home");
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

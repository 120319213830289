import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import Countdown from "react-countdown";

import A from "../../utils/API";

import useUser from "../../hooks/useUser";
import useLang from "../../hooks/useLang";

import { BsChevronLeft } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import useUtils from "../../hooks/useUtils";

export default function Participate() {
  const navigate = useNavigate();
  const { lang, activeLang } = useLang();
  const { closeWindow, eventLog, exitConfirm, playClick, playSuccess } =
    useUtils();
  const { id } = useParams();
  const { headers } = useUser();
  const [question, setQuestion] = React.useState(false);
  const [result, setResult] = React.useState(false);
  const [selectedAnswer, setSelectedAnswer] = React.useState(false);

  const [answerLoading, setAnswerLoading] = React.useState(false);

  const [timeUp, setTimeUp] = React.useState(false);

  const fetchQuestion = () =>
    axios
      .post(
        A.HOST + A.START_QUIZ,
        {
          quizId: id,
          langCode: activeLang,
        },
        headers
      )
      .then(({ data }) => setQuestion(data))
      .catch((err) => {
        closeWindow();
      });

  const initiateAnswer = async (answer = "") => {
    if (timeUp) return;
    setAnswerLoading(true);
    try {
      setSelectedAnswer(answer);
      const { data } = await axios.post(
        A.HOST + A.ANSWER_QUIZ,
        {
          quizId: id,
          answer: answer,
          langCode: activeLang,
        },
        headers
      );
      setResult(data);
    } catch (err) {
      closeWindow();
    }
    setAnswerLoading(false);
  };

  const timeout = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.QUIZ_TIMEOUT,
        {
          quizId: id,
          langCode: activeLang,
        },
        headers
      );
      navigate("/timeout", { state: data });
    } catch (err) {
      closeWindow();
    }
  };

  React.useEffect(() => {
    if (timeUp) timeout();
  }, [timeUp]);

  React.useEffect(() => {
    fetchQuestion();
  }, []);

  React.useEffect(() => {
    if (result?.correctAnswers) {
      setTimeout(() => {
        navigate("/results/" + id, { state: { quizId: id, ...result } });
      }, 1000);
    }
  }, [result]);

  React.useEffect(() => {
    if (question) exitConfirm();
  }, [question]);

  const getColor = (answer) => {
    let color = undefined;
    if (result && result?.correctAnswers) {
      if (result?.result === false && selectedAnswer === answer) color = "red";
      result?.correctAnswers?.forEach((each) => {
        if (each === answer) color = "green";
      });
    }

    return color;
  };

  return question ? (
    <div className="bg-blue-500 py-10 min-h-screen flex flex-col justify-center items-center">
      {/* <BsChevronLeft className="text-[30px] mr-auto " /> */}
      {/* {JSON.stringify(result, null, 4)} */}
      <div className="max-w-[80vw] flex mb-2 items-center">
        <AiOutlineInfoCircle className="text-xl" />
        <p className="ml-2">{lang?.WARNING}</p>
      </div>
      {answerLoading === false && selectedAnswer === false && (
        <div className="m-3 bg-blue-500 h-22 w-22 border-4 rounded-full p-8 fixed flex justify-center items-center right-0 bottom-0">
          <p className="fixed text-2xl font-bold">
            <Countdown
              onComplete={() => {
                setTimeUp(true);
              }}
              date={Date.now() + question?.duration * 1000}
              renderer={({ hours, minutes, seconds, completed }) => {
                return <span>{seconds}</span>;
              }}
            />
          </p>
        </div>
      )}
      <div className="py-2">
        <h3 className="max-w-[600px] text-center indent-3 text-lg rounded-xl bg-white text-black font-bold mx-5 p-4">
          {question?.question}
        </h3>
      </div>
      <img
        className="rounded-md w-[90vw] bg-blue-400 object-cover w-full mt-3"
        src={question?.imageUrl}
        style={{
          aspectRatio: 16 / 9,
        }}
      />
      <div className="flex flex-col items-center w-full max-w-[600px] mx-3 px-5 pt-4 text-xl font-bold">
        {question?.answers?.map((each) => (
          <button
            onClick={() => {
              if (answerLoading === false) {
                initiateAnswer(each);
                eventLog("ANSWER_CLICK", id);
                playClick();
              }
            }}
            //bg-gradient-to-r from-[#46456D]
            className="border rounded-md w-full px-3 h-14 my-2 pl-4 text-left"
            style={{
              backgroundColor: getColor(each),
              opacity:
                answerLoading === true && selectedAnswer !== each ? 0.5 : 1,
            }}
          >
            {each}
          </button>
        ))}
      </div>
    </div>
  ) : (
    <div className="bg-blue-500 h-screen w-screen"></div>
  );
}

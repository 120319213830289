import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import ReactPlayer from "react-player";
import axios from "axios";

import { format } from "date-fns";

import { BiRupee } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";

import A from "../../utils/API";

import useLang from "../../hooks/useLang";
import useUser from "../../hooks/useUser";
import useUtils from "../../hooks/useUtils";

import { IoIosArrowBack } from "react-icons/io";
import Results from "../../components/Results";

const Home = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { closeWindow, eventLog } = useUtils();

  const token = searchParams.get("token");
  const { user, setUser, headers } = useUser();

  if (!token && !user) closeWindow();

  React.useEffect(() => {
    setUser({ accessToken: token });
  }, []);

  React.useEffect(() => {
    if (user) {
      // eventLog("QUIZ_PAGE_INITIATED");
      navigate("/home?page=QUIZ", {
        replace: true,
      });
    }
  }, [user?.accessToken]);

  return <></>;
};

export default Home;
